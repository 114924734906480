<div class="mat-dialog-title">
  <div class="title"> {{folio}}Nueva orden de servicio</div>
  <div class="spacer"></div>
  <div class="buttons">
    <button type="button" class="title-button" (click)="close()">
      <mat-icon class="title-button-icon">clear</mat-icon>
    </button>
  </div>
</div>

<div class="mat-dialog-body">
  <div class="bs-content-full-area" [style.height.%]="100">
    <div class="bs-sidebar-left" [style.width.px]="250">
      <div class="bs-content">
        <div class="row">
          <div class="col text-center">
            <span class="material-icons" [style.fontSize.px]="80">description</span>
          </div>
        </div>
        <div class="bs-row">
          <div class="bs-col">
            <mat-selection-list [(ngModel)]="objScene" [multiple]="false">
              <mat-list-option  [value]="'CLIENTE'">Cliente</mat-list-option>
              <mat-list-option  [value]="'REQUERIMIENTO'">Requerimientos</mat-list-option>
              <mat-list-option  [value]="'DIRECCIONES'">Direcciones</mat-list-option>
              <mat-list-option  [value]="'OPERACION'">Operación</mat-list-option>
            </mat-selection-list>
          </div>
        </div>
      </div>
    </div>
    <div class="bs-viewport">
      <!--   INICIO SOLICITUD   -->
      <div class="datatable-responsive layout" [hidden]="objScene[0] != 'CLIENTE'">
        <div class="bs-content">
          <div fxLayout="row wrap">
            <!-- Card column -->
            <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
              <mat-card class="panel-content-card">
                <div class="content-card" style="padding: 20px; ">
                  <div class="card-row">
                    <div class="card-col">
                      <div class="img-foto" [style.background]="'url(' + objApplicationConfig.ENDPOINT + 'api/empresas/logotipo/' + objOrdenPedido.idEmpresa + '/512)'"></div>
                    </div>
                    <div class="card-col" [style.paddingLeft.px]="30">
                      <div class="card-row">
                        <div class="card-col-fiel card-fiel" style="font-size: 18px;font-weight: 500;" >{{objOrdenPedido.empresaNombreComercial}}</div>
                      </div>
                      <div class="card-row">
                        <div class="card-col-fiel card-fiel">{{objOrdenPedido.empresaRazonSocial}}</div>
                      </div>
                      <div class="card-row" [style.paddingTop.px]="10">
                        <div class="card-col-fiel card-fiel">{{objOrdenPedido.empresaEmail}}</div>
                      </div>
                      <div class="card-row">
                        <div class="card-col-fiel card-fiel">{{objOrdenPedido.empresaTelefono}}</div>
                      </div>
                      <div class="card-row">
                        <div class="card-col-fiel card-fiel">{{objOrdenPedido.empresaDireccionFiscal}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-card>
            </div>
          </div>

          <div fxLayout="row wrap">
            <!-- Card column -->
            <div fxFlex.gt-lg="100" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
              <mat-card class="panel-content-card">
                <div class="content-card">
                  <div class="card-row br_btm">
                    <div class="card-col-title">Cliente</div>
                  </div>
                  <div class="card-row">
                    <div class="card-col">
                      <div class="card-row" style="margin: 5px 20px 0 20px">
                        <div class="card-col-label card-label">Nombre comercial</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px 0 20px">
                        <div class="card-col-label card-label">Razón social</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px 0 20px">
                        <div class="card-col-label card-label">R.F.F</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px 0 20px">
                        <div class="card-col-label card-label">Dirección fiscal</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px; padding-bottom: 15px">
                        <div class="card-col-label card-label">Dirección social</div>
                      </div>
                    </div>
                    <div class="card-col">
                      <div class="card-row" style="margin: 5px 20px 0 20px">
                        <div class="card-col-fiel card-fiel">{{objOrdenPedido.clienteNombreComercial}}</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px 0 20px">
                        <div class="card-col-fiel card-fiel">{{objOrdenPedido.clienteRazonSocial}}</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px 0 20px">
                        <div class="card-col-fiel card-fiel">{{objOrdenPedido.clienteRfc}}</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px 0 20px">
                        <div class="card-col-fiel card-fiel">{{objOrdenPedido.clienteDireccionFiscal}}</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px; padding-bottom: 15px">
                        <div class="card-col-fiel card-fiel">{{objOrdenPedido.clienteDireccionSocial}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-card>
            </div>

            <div fxFlex.gt-lg="100" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
              <mat-card class="panel-content-card">
                <div class="content-card">
                  <div class="card-row br_btm">
                    <div class="card-col-title">Contacto</div>
                  </div>
                  <div class="card-row">
                    <div class="card-col">
                      <div class="card-row" style="margin: 5px 20px 0 20px">
                        <div class="card-col-label card-label">Nombre</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px 0 20px">
                        <div class="card-col-label card-label">Puesto</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px 0 20px">
                        <div class="card-col-label card-label">Teléfono</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px; padding-bottom: 15px">
                        <div class="card-col-label card-label">Correo</div>
                      </div>
                    </div>
                    <div class="card-col">
                      <div class="card-row" style="margin: 5px 20px 0 20px">
                        <div class="card-col-fiel card-fiel">{{objOrdenPedido.contactoNombreCompleto}}</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px 0 20px">
                        <div class="card-col-fiel card-fiel">{{objOrdenPedido.contactoPuesto}}</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px 0 20px">
                        <div class="card-col-fiel card-fiel">{{objOrdenPedido.contactoTelefono}}</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px; padding-bottom: 15px">
                        <div class="card-col-fiel card-fiel">{{objOrdenPedido.contactoEmail}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-card>
            </div>
          </div>

        </div>
      </div>
      <!--   FIN SOLICITUD   -->

      <div class="datatable-responsive layout" [hidden]="objScene[0] != 'REQUERIMIENTO'">
        <div class="bs-content">
          <div fxLayout="row wrap">
            <!-- Card column -->
            <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
              <mat-card class="panel-content-card">
                <div class="content-card">
                  <div class="card-row br_btm">
                    <div class="card-col-title">Alcance</div>
                  </div>
                  <div class="card-row" style="margin: 5px 20px 0 20px">
                    <div class="card-col-label card-fiel">{{objCotizacion?.alcance}}</div>
                  </div>
                </div>
              </mat-card>
            </div>
          </div>

          <div fxLayout="row wrap">
            <!-- Card column -->
            <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
              <mat-card class="panel-content-card">
                <div class="content-card">
                  <div class="card-row br_btm">
                    <div class="card-col-title">Descripción del servicio</div>
                  </div>
                  <div class="card-row" style="margin: 5px 20px 0 20px">
                    <div class="card-col-label card-fiel">{{objCotizacion?.descripcion}}</div>
                  </div>
                </div>
              </mat-card>
            </div>
          </div>
        </div>
        <div class="">
          <div class="title-card-bar">
            <span class="title">Partidas aprobadas</span>
          </div>
          <table class="datatable">
            <thead>
            <tr>
              <th [style.width.px]="40" class="text-center">No.</th>
              <th [style.width.px]="100">Servicio</th>
              <th>Descripción</th>
              <th [style.width.px]="100">Cantidad</th>
              <th [style.width.px]="100">Unidad</th>
            </tr>
            </thead>
            <tbody>

            <tr *ngFor="let itemCotizacionPartidas of lstCotizacionPartidas; let index = index;">
              <td class="text-center">{{index + 1}}</td>
              <td>{{ itemCotizacionPartidas?.servicio?.servicio }}</td>
              <td>{{ itemCotizacionPartidas?.concepto }}</td>
              <td class="text-center">{{ itemCotizacionPartidas?.cantidad }}</td>
              <td>{{ itemCotizacionPartidas?.unidadMedida?.unidadMedida }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!--   INICIO PROPUESTA   -->
      <div class="datatable-responsive layout" [hidden]="objScene[0] != 'PROGRAMACION'">
        <div class="bs-content">
          <div class="bs-row">
            <div class="bs-col">
              <div class="card-col-title">Duración del contrato</div>
            </div>
          </div>
          <form [formGroup]="fgOrdenServicioProgramacion">
            <div class="bs-row">
              <div class="bs-col-1" style="width: 120px;">
                <mat-form-field appearance="legacy">
                  <mat-label>Desde</mat-label>
                  <input matInput class="text-center" [matDatepicker]="pickerStart" formControlName="fechaDesde" [readonly]="true" (click)="pickerStart.open()">
                  <mat-datepicker-toggle matSuffix [for]="pickerStart"></mat-datepicker-toggle>
                  <mat-datepicker #pickerStart></mat-datepicker>
                </mat-form-field>
              </div>

              <div class="bs-col-1" style="width: 120px;">
                <mat-form-field appearance="legacy">
                  <mat-label>Hasta</mat-label>
                  <input matInput class="text-center" [matDatepicker]="pickerEnd" formControlName="fechaHasta" [readonly]="true" (click)="pickerEnd.open()">
                  <mat-datepicker-toggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
                  <mat-datepicker #pickerEnd></mat-datepicker>
                </mat-form-field>
              </div>

              <div class="bs-col">
                <mat-form-field appearance="legacy">
                  <mat-label>Objetivo del contrato</mat-label>
                  <input matInput formControlName="objetivo">
                </mat-form-field>
              </div>
            </div>

            <div class="bs-row">
              <div class="bs-col">
                <mat-form-field appearance="legacy">
                  <textarea matInput formControlName="descripcion" placeholder="Descripción del servicio" rows="10" cols="70"></textarea>
                </mat-form-field>
              </div>
            </div>
          </form>

        </div>
      </div>
      <!--   FIN PROPUESTA   -->

      <div class="datatable-responsive layout" [hidden]="objScene[0] != 'DIRECCIONES'">
        <div class="bs-content">
          <form [formGroup]="fgOrdenServicioDirecciones">
            <div class="bs-row">
              <div class="bs-col input-comentario">
                <mat-form-field>
                  <textarea matInput formControlName="origen" maxlength="4095" placeholder="Lugar de origen" rows="10"></textarea>
                </mat-form-field>
              </div>
            </div>
            <div class="bs-row">
              <div class="bs-col input-comentario">
                <mat-form-field>
                  <textarea matInput formControlName="destino" maxlength="4095" placeholder="Lugar de destino" rows="10"></textarea>
                </mat-form-field>
              </div>
            </div>
          </form>
        </div>
      </div>

      <!--   INICIO COMENTARIOS   -->
      <div class="datatable-responsive layout" [hidden]="objScene[0] != 'OPERACION'">
        <div class="options-bar">
          <div class="bs-content">
            <div class="bs-row">
              <div class="bs-col">
                Tiempos de ejecución
              </div>
            </div>
          </div>
        </div>
        <div class="main">
          <form [formGroup]="fgOrdenServicioTiemposEjecucion">
            <table class="datatable" style="min-width: 50%">
              <thead>
              <tr>
                <th [style.width.px]="135"></th>
                <th [style.width.px]="130">Fecha</th>
                <th [style.width.px]="110">Hora</th>
                <th [style.width.px]="130">Odometro</th>
                <th [style.width.px]="130">Horometro</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>Fecha programada</td>
                <td>
                  <div class="table-input-calendar">
                    <input class="text-center" matInput formControlName="programadaFecha" [matDatepicker]="matDateProgramada" [readonly]="true" (click)="matDateProgramada.open()">
                    <mat-datepicker #matDateProgramada></mat-datepicker>
                    <button mat-raised-button (click)="matDateProgramada.open()"><span class="material-icons">today</span></button>
                  </div>
                </td>
                <td>
                  <div class="inputs-content">
                    <input type="text"
                           class="table-input text-center timeInput"
                           mask="Hh:m0"
                           [leadZeroDateTime]="true"
                           matInput
                           maxlength="5"
                           autocomplete="off"
                           formControlName="programadaHora"
                           (keyup)="inputProgramadaTime_keyUpEvent($event)">
                    <span class="suffixSpan">Hrs.</span>
                  </div>
                </td>
                <td class="text-center">---</td>
                <td class="text-center">---</td>
              </tr>
              <tr>
                <td
                  [class.required]="fgOrdenServicioTiemposEjecucion.controls['salidaOrigenFecha'].hasError('required')  && fgOrdenServicioTiemposEjecucion.controls['salidaOrigenFecha'].touched">
                  Salida del origen *
                </td>
                <td>
                  <div class="table-input-calendar" [class.required]="fgOrdenServicioTiemposEjecucion.controls['salidaOrigenFecha'].hasError('required')  && fgOrdenServicioTiemposEjecucion.controls['salidaOrigenFecha'].touched">
                    <input class="text-center" matInput formControlName="salidaOrigenFecha" [matDatepicker]="matDateFin" [readonly]="true" (click)="matDateFin.open()">
                    <mat-datepicker #matDateFin></mat-datepicker>
                    <button mat-raised-button (click)="matDateFin.open()"><span class="material-icons">today</span></button>
                  </div>
                </td>
                <td>
                  <div class="inputs-content">
                    <input type="text"
                           class="table-input text-center"
                           mask="Hh:m0"
                           [leadZeroDateTime]="true"
                           matInput
                           maxlength="5"
                           autocomplete="off"
                           formControlName="salidaOrigenHora"
                           [class.required]="fgOrdenServicioTiemposEjecucion.controls['salidaOrigenHora'].hasError('required')  && fgOrdenServicioTiemposEjecucion.controls['salidaOrigenHora'].touched"
                           (keyup)="inputSalidaOrigenTime_keyUpEvent($event)">
                    <span class="suffixSpan" [class.required]="fgOrdenServicioTiemposEjecucion.controls['salidaOrigenHora'].hasError('required')  && fgOrdenServicioTiemposEjecucion.controls['salidaOrigenHora'].touched">Hrs.</span>
                  </div>
                </td>
                <td>
                  <div class="table-input text-right claseInput" [hidden]="(!hasPrincipal || !boolOdometro)">{{ fgOrdenServicioTiemposEjecucion.controls['salidaOrigenOdometro'].value | number: '1.'}}
                    <span class="suffixSpan">Kms.</span></div>
                  <div class="text-center" [hidden]="(hasPrincipal && boolOdometro)">---</div>
                </td>
                <td>
                  <div class="table-input text-center claseInput" [hidden]="(!hasPrincipal || !boolHorometro)">{{ fgOrdenServicioTiemposEjecucion.controls['salidaOrigenHorometro'].value | number: '1.'}}
                    <span class="suffixSpan">Hrs.</span></div>
                  <div class="text-center" [hidden]="(hasPrincipal && boolHorometro)">---</div>
                </td>
              </tr>
              </tbody>
            </table>
          </form>
        </div>
        <div class="options-bar" style="margin-top:20px">
          <div class="bs-content">
            <div class="bs-row">
              <div class="bs-col">
                Unidades y operadores
              </div>
            </div>
          </div>
        </div>
        <div class="options-bar">
          <div class="option">
            <button type="button" matTooltip="Nuevo" [disabled]="btnOptionBar.disabled" (click)="btnUnidadAppend_clickEvent()">
              <span class="material-icons">add</span>
            </button>
          </div>
          <div class="option">
            <button type="button" matTooltip="Eliminar" [disabled]="!boolChkSomeOrdenServicioUnidad || btnOptionBar.disabled" (click)="btnUnidadDelete_clickEvent()">
              <span class="material-icons">clear</span>
            </button>
          </div>
        </div>
        <div>
          <table class="datatable">
            <thead>
            <tr [style.maxHeight.px]="20">
              <th [style.width.px]="40" class="text-center"><mat-checkbox [disabled]="objChkBox.disabled" [checked]="boolChkOrdenServicioUnidad" (change)="chkOrdenServicioUnidadSelected_changeEvent($event.checked)"></mat-checkbox></th>
              <th [style.width.px]="50">Acc.</th>
              <th>Unidad</th>
              <th [style.width.%]="50">Operadores</th>
            </tr>
            </thead>
            <tbody>
            <tr app-ordenes-servicio-unidad-item style="height: 100px"
                #compUnidadesItem
                *ngFor="let itemUnidad of lstUnidades_Usuarios; let index = index;"
                [index]="index"
                [hasPrincipal]="hasPrincipal"
                [lstOrdenesServiciosMiembros_UsuariosPuestos]="lstOrdenesServiciosMiembros_UsuariosPuestos"
                (onDelete)="btnPartidasDelete_onDeleteEvent($event)"
                (onChecked)="chkItemOrdenServicioUnidadSelected_changeEvent()"
                (onPrincipal)="selectUnidadPrincipal($event)"
                (onOdometro)="selectUnidadOdometro($event)"
                (onHorometro)="selectUnidadHorometro($event)"
                (onDeleteOperadores)="chkDeleteOperador_changeEvent()"
                (onAddOperadores)="chkAddOperador_changeEvent()"
            ></tr>
            </tbody>
          </table>
        </div>
      </div>
      <!--   FIN COMENTARIOS   -->

    </div>
  </div>
</div>

<div class="mat-dialog-loader">
  <mat-progress-bar class="loader" *ngIf="objLoader.visible" color="accent" mode="indeterminate"></mat-progress-bar>
</div>

<div class="mat-dialog-actions">
  <button class="primary" type="button" [disabled]="btnAceptar.disabled" (click)="formOrdenServicioCreate_submitEvent()">Aceptar</button>
  <button class="gray-color" type="button" [disabled]="btnCancelar.disabled" (click)="close()">Cancelar</button>
</div>

