<div class="flex-container" (contextmenu)="$event.preventDefault();">
  <div class="mat-dialog-title" [style.width.%]="100">
    <div class="title">Historial de gerenciamiento</div>
    <div class="spacer"></div>
    <div class="buttons">
      <button type="button" class="title-button" (click)="close()">
        <mat-icon class="title-button-icon">clear</mat-icon>
      </button>
    </div>
  </div>

  <div class="content">
    <div class="mat-dialog-body mat-dialog-no-actions">
      <div class="bs-content-full-area">
        <div class="datatable-responsive layout">

          <div class="options-bar"></div>

          <table #dataTable class="datatable">
            <thead>
            <tr>
              <th [style.width.px]="40" class="text-center">
                <mat-checkbox (change)="chkUnidadesMarcasSelected_changeEvent($event.checked)"></mat-checkbox>
              </th>
              <th [style.width.px]="40"  >No.</th>
              <th [style.width.px]="160" [style.maxWidth.px]="180">Orden Pedido</th>
              <th [style.width.px]="160" [style.maxWidth.px]="180">Orden Servicio</th>
              <th>No. Económico</th>
              <th [style.width.px]="120" [style.maxWidth.px]="140">Fecha | salida origen</th>
              <th [style.width.px]="140" [style.maxWidth.px]="160">Odómetro | salida origen</th>
              <th [style.width.px]="150" [style.maxWidth.px]="170">Horómetro | salida origen</th>
              <th [style.width.px]="150" [style.maxWidth.px]="170">Fecha | último check point</th>
              <th [style.width.px]="175" [style.maxWidth.px]="195">Odómetro | último check point</th>
              <th [style.width.px]="175" [style.maxWidth.px]="195">Horómetro | último check point</th>
            </tr>
            </thead>
            <tbody>

            <tr *ngFor="let itemGerenciamientoLog of lstGerenciamientosLog; let index = index;">
              <td class="text-center">
                <mat-checkbox [(ngModel)]="itemGerenciamientoLog.checked" (ngModelChange)="chkItemUnidadesMarcasSelected_changeEvent()"></mat-checkbox>
              </td>
              <td class="text-center">{{(index + 1)}}</td>
              <td class="text-center">
                <div class="badge label-badge">{{itemGerenciamientoLog.ordenPedidoFolio}}</div>
              </td>
              <td class="text-center">
                <div class="badge label-badge">{{itemGerenciamientoLog.ordenServicioFolio}}</div>
              </td>
              <td>
                <div class="item-menu-context">
                  <div class="text-column text-ellipsis" >{{itemGerenciamientoLog.numeroEconomico}}</div>
                  <button class="datatable-button menu-contextual" [matMenuTriggerFor]="menuContextual">
                    <mat-icon class="datatable-button-icon">more_vert</mat-icon>
                  </button>
                  <mat-menu #menuContextual="matMenu" class="itemMenu">
                    <button mat-menu-item (click)="btnOrdenServicioGerenciamiento_clickEvent(itemGerenciamientoLog)">
                      <mat-icon class="material-icons-outlined">edit</mat-icon>
                      <span>Evidencia</span>
                    </button>
                  </mat-menu>
                </div>
<!--                {{itemGerenciamientoLog.numeroEconomico}}-->
              </td>
              <td class="text-center">{{itemGerenciamientoLog.salidaOrigenFechaHoraNatural}}</td>
              <td class="text-center">{{itemGerenciamientoLog.salidaOrigenOdometroNatural}}</td>
              <td class="text-center">{{itemGerenciamientoLog.salidaOrigenHorometroNatural}}</td>
              <td class="text-center">{{itemGerenciamientoLog.actualFechaHoraNatural}}</td>
              <td class="text-center">{{itemGerenciamientoLog.actualOdometroNatural}}</td>
              <td class="text-center">{{itemGerenciamientoLog.actualHorometroNatural}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="mat-dialog-loader mat-dialog-no-actions">
    <mat-progress-bar class="loader" *ngIf="objLoader.visible" color="accent" mode="indeterminate"></mat-progress-bar>
  </div>
</div>
