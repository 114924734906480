import { Injectable, OnDestroy } from "@angular/core";
import { Observable, BehaviorSubject, ReplaySubject } from "rxjs";


@Injectable()
export class WebSocketShareService implements OnDestroy {

  private cotizacionDataSubject : BehaviorSubject<any> = new BehaviorSubject<any>([]);
  private ordenPedidoDataSubject : BehaviorSubject<any> = new BehaviorSubject<any>([]);
  private ordenServicioDataSubject : BehaviorSubject<any> = new BehaviorSubject<any>([]);
  private ordenMantenimientoDataSubject : BehaviorSubject<any> = new BehaviorSubject<any>([]);
  private mantenimientoLogDataSubject : BehaviorSubject<any> = new BehaviorSubject<any>([]);
  private mantenimientoTallerLogDataSubject : BehaviorSubject<any> = new BehaviorSubject<any>([]);

  constructor() { }

  ngOnDestroy(): void {
    this.cotizacionDataSubject.unsubscribe();
    this.ordenPedidoDataSubject.unsubscribe();
    this.ordenServicioDataSubject.unsubscribe();
    this.ordenMantenimientoDataSubject.unsubscribe();
    this.mantenimientoLogDataSubject.unsubscribe();
    this.mantenimientoTallerLogDataSubject.unsubscribe();
  }

  onCotizacionNewValueReceive(value: any) {
    this.cotizacionDataSubject.next(value);
  }

  getCotizacionNewValue(): Observable<any> {
    return this.cotizacionDataSubject.asObservable();
  }

  onOrdenPedidoNewValueReceive(value: any) {
    this.ordenPedidoDataSubject.next(value);
  }

  getOrdenPedidoNewValue(): Observable<any> {
    return this.ordenPedidoDataSubject.asObservable();
  }

  onOrdenServicioNewValueReceive(value: any) {
    this.ordenServicioDataSubject.next(value);
  }

  getOrdenServicioNewValue(): Observable<any> {
    return this.ordenServicioDataSubject.asObservable();
  }

  onOrdenMantenimientoNewValueReceive(value: any) {
    this.ordenMantenimientoDataSubject.next(value);
  }

  getOrdenMantenimientoNewValue(): Observable<any> {
    return this.ordenMantenimientoDataSubject.asObservable();
  }

  onMantenimientoLogNewValueReceive(value: any) {
    this.mantenimientoLogDataSubject.next(value);
  }

  getMantenimientoLogNewValue(): Observable<any> {
    return this.mantenimientoLogDataSubject.asObservable();
  }

  onMantenimientoTallerLogNewValueReceive(value: any) {
    this.mantenimientoTallerLogDataSubject.next(value);
  }

  getMantenimientoTallerLogNewValue(): Observable<any> {
    return this.mantenimientoTallerLogDataSubject.asObservable();
  }
}
