import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Globals } from 'src/app/libraries/globals';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {

  @Input('titulo') titulo : string = '';
  @Input('mensaje') mensaje : string = '';
  @Input('mensajeHTML') mensajeHTML : string = '';
  @Input('acceptLabel') acceptLabel : string = 'Aceptar';
  @Input('cancelLabel') cancelLabel : string = 'Cancelar';
  @Input('autoCloseDelay') autoCloseDelay : number = 0;
  @Input('fnClose') fnClose : any;
  @Input('fnAccept') fnAccept : any;
  @Input('fnCancel') fnCancel : any;

  @Output('onClose') onClose : EventEmitter<ConfirmComponent> = new EventEmitter<ConfirmComponent>();

  constructor(  public objDialogRef: MatDialogRef<ConfirmComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
    if( Globals.exist(data.titulo) ) this.titulo = data.titulo;
    if( Globals.exist(data.mensaje) ) this.mensaje = data.mensaje;
    if( Globals.exist(data.mensajeHTML) ) this.mensajeHTML = data.mensajeHTML;
    if( Globals.exist(data.acceptLabel) ) this.acceptLabel = data.acceptLabel;
    if( Globals.exist(data.cancelLabel) ) this.cancelLabel = data.cancelLabel;
    if( Globals.exist(data.autoCloseDelay) ) this.autoCloseDelay = data.autoCloseDelay;
    if( Globals.exist(data.fnClose) ) this.fnClose = data.fnClose;
    if( Globals.exist(data.fnAccept) ) this.fnAccept = data.fnAccept;
    if( Globals.exist(data.fnCancel) ) this.fnCancel = data.fnCancel;
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() :void {
    setTimeout(() => {
      if( this.autoCloseDelay > 0 ) {
        setTimeout(() => {
          this.close();
        }, this.autoCloseDelay);
      }
    }, 0);
  }

  public close() : void {
    this.objDialogRef.close();
    if( Globals.exist(this.fnClose) ) this.fnClose();
    this.onClose.emit(this);
  }

  public btnAceptar_clickEvent() : void {
    if( Globals.exist(this.fnAccept) ) this.fnAccept();
    this.close();
  }

  public btnCancelar_clickEvent() : void {
    if( Globals.exist(this.fnCancel) ) this.fnCancel();
    this.close();
  }

  public btnClose_clickEvent() : void {
    this.close();
  }

}
