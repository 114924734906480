<div class="mat-dialog-title">
  <div class="title"> Enviar correo </div>
  <div class="spacer"></div>
  <div class="buttons">
    <button type="button" class="title-button" (click)="close()">
      <mat-icon class="title-button-icon">clear</mat-icon>
    </button>
  </div>
</div>

<div class="mat-dialog-body">
    <div class="bs-content">
      <form [formGroup]="fgUsuarioSendEmail">
        <div class="bs-row">
          <div class="bs-col">
            <mat-form-field>
              <mat-label>Empresa</mat-label>
              <mat-select formControlName="empresa" (selectionChange)="getEmpresaSelected_selectedEvent($event.value)" required>
                <mat-option *ngFor="let itemEmpresa of lstEmpresas" [value]="itemEmpresa.idEmpresa">{{itemEmpresa.nombreComercial}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="bs-row">
          <div class="bs-col">
            <mat-form-field>
              <mat-label>Remitente</mat-label>
              <input type="text" matInput formControlName="remitente" required maxlength="256" placeholder="Remitente" autocomplete="off">
              <span class="material-icons icon-check" [style.color]="(isValidEmail)?'#229954':'#D50000'" matSuffix >
                {{(isValidEmail) ? 'check_circle' : 'cancel'}}
              </span>
            </mat-form-field>
          </div>
          <div class="bs-col conf-email">
            <button type="button" [disabled]="btnConfEmail.disabled" matTooltip="Configuración de correo de envío" (click)="btnSettingSendEmail_clickEvent()" ><mat-icon class="title-button-icon">settings</mat-icon></button>
          </div>
        </div>
        <div class="bs-row">
          <div class="bs-col">
            <mat-form-field class="destinatarios-chip-list">
              <mat-label>Destinatario(s)</mat-label>
              <mat-chip-list #chipList aria-label="Destinatarios" formControlName="destinatario" required>
                <mat-chip *ngFor="let itemDestinatario of lstDestinatarios" [selectable]="selectable" [removable]="removable" (removed)="btnRemoverDestinatario_clickEvent(itemDestinatario)"> {{itemDestinatario.name}}
                  <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                </mat-chip>
                <input
                  placeholder="Destinatario(s)"
                  [matChipInputFor]="chipList"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  (matChipInputTokenEnd)="btnAgregarDestinatario_clickEvent($event)"
                >
              </mat-chip-list>
            </mat-form-field>
          </div>
        </div>
        <div class="bs-row">
          <div class="bs-col">
            <mat-form-field>
              <input type="text" matInput formControlName="asunto" required maxlength="256" placeholder="Asunto" autocomplete="off">
            </mat-form-field>
          </div>
        </div>
        <div class="bs-row">
          <div class="bs-col">
            <mat-form-field class="example-full-width" appearance="legacy">
              <mat-label>Cliente</mat-label>
              <input app-input-autocomplete #txtCliente [display]="['nombreComercial']" type="text" placeholder="Nombre Comercial" aria-label="Cliente" matInput formControlName="clienteNombreComercial" required (keyup)="txtClientes_keyUpEvent($event)" [matAutocomplete]="autoSolicitudCliente" (focusout)="txtCliente_OnFocusOutEvent()">
              <mat-icon matSuffix *ngIf="!txtCliente.loading" >{{txtCliente.getIcon()}}</mat-icon>
              <mat-spinner matSuffix *ngIf="txtCliente.loading" style="margin:0 auto" [diameter]="18" ></mat-spinner>
              <mat-autocomplete autoActiveFirstOption="false" #autoSolicitudCliente="matAutocomplete" (optionSelected)="getClienteSelected_selectedEvent($event.option.value)" >
                <mat-option *ngFor="let itemCliente of lstClientes" [value]="itemCliente.idCliente">
                  <span class="title-option">{{itemCliente.nombreComercial}}</span>
                  <span class="subtitle-option" >{{itemCliente.razonSocial}} - {{itemCliente.rfc}}</span>
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
        </div>
        <div class="bs-row">
          <div class="bs-col">
            <mat-form-field class="example-full-width" appearance="legacy">
              <mat-label>Contacto</mat-label>
              <input app-input-autocomplete #txtClienteContacto [display]="['nombreCompleto']" type="text" placeholder="Nombre" aria-label="Contacto" matInput formControlName="contactoNombreCompleto" required (keyup)="txtClienteContactos_keyUpEvent($event)" [matAutocomplete]="autoSolicitudClienteContacto" >
              <mat-icon matSuffix *ngIf="!txtClienteContacto.loading" >{{txtClienteContacto.getIcon()}}</mat-icon>
              <mat-spinner matSuffix *ngIf="txtClienteContacto.loading" style="margin:0 auto" [diameter]="18" ></mat-spinner>
              <mat-autocomplete autoActiveFirstOption="false" #autoSolicitudClienteContacto="matAutocomplete" (optionSelected)="getClienteContactoSelected_selectedEvent($event.option.value)">
                <mat-option *ngFor="let itemClienteContacto of lstClientesContactosFiltered | async" [value]="itemClienteContacto.nombreCompleto">
                  <span class="title-option">{{itemClienteContacto.nombreCompleto}}</span>
                  <span class="subtitle-option" >{{itemClienteContacto.email}}</span>
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
        </div>
        <div class="bs-row">
          <div class="bs-col">
            <p-editor formControlName="mensaje" [style]="{'height':'320px'}" [readonly]="inputPanel.disabled">
              <ng-template pTemplate="header">
                <span class="ql-formats">
                  <button type="button" class="ql-bold" aria-label="Bold"></button>
                  <button type="button" class="ql-italic" aria-label="Italic"></button>
                  <button type="button" class="ql-underline" aria-label="Underline"></button>
                </span>
              </ng-template>
            </p-editor>
          </div>
        </div>
      </form>
      <div class="bs-row" *ngIf="lstUsuariosFicherosVigentes.length > 0">
        <div class="bs-col">
          <div class="bs-row">
            <div class="bs-col">
              <div class="section-title"><span>Documentos del usuario</span></div>
            </div>
          </div>
          <div class="bs-row">
            <div class="bs-col">
              <table class="datatable">
                <thead>
                <tr>
                  <th [style.width.px]="40" class="text-center"></th>
                  <th>Nombre</th>
                  <th [style.width.px]="100">Tamaño</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let itemUsuarioFichero of lstUsuariosFicherosVigentes; let index = index;">
                  <td class="text-center"><mat-checkbox [(ngModel)]="itemUsuarioFichero.checked" [disabled]="chckLstFichero.disabled" (ngModelChange)="chkItemUsuarioFicheroVigente_changeEvent(index)"></mat-checkbox></td>
                  <td>{{itemUsuarioFichero.nombre}}</td>
                  <td class="text-right" [style.paddingRight.px]="10" >{{itemUsuarioFichero.tamano | FileSize}}</td>
                </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td [style.paddingLeft.px]="10" colspan="2" ><span>{{numberItems}}&nbsp;Elementos seleccionados:</span></td>
                    <td class="footer-file-size" ><span>{{fileSize | FileSize}}</span></td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>

<div class="mat-dialog-loader">
  <mat-progress-bar class="loader" *ngIf="objLoader.visible" color="accent" mode="indeterminate"></mat-progress-bar>
</div>

<div class="mat-dialog-actions">
  <button class="primary" type="button" [disabled]="btnAceptar.disabled" (click)="formUsuarioEnviarCorreo_submitEvent()">Enviar</button>
  <button class="gray-color" type="button" [disabled]="btnCancelar.disabled" (click)="close()">Cancelar</button>
</div>
