<div class="flex-container" (contextmenu)="$event.preventDefault();">
  <div class="mat-dialog-title" [style.width.%]="100">
    <div class="title">Documentos de {{nombreCompleto}}</div>
    <div class="spacer"></div>
    <div class="buttons">
      <button type="button" class="title-button" (click)="close()">
        <mat-icon class="title-button-icon">clear</mat-icon>
      </button>
    </div>
  </div>

  <div class="mat-dialog-body mat-dialog-no-actions">

    <div class="bs-content-full-area" [style.height.%]="100">
      <div class="bs-sidebar-left" [style.width.px]="250">
        <div class="bs-content">
          <div class="row">
            <div class="col text-center">
              <span class="material-icons" [style.fontSize.px]="64">folder_open</span>
            </div>
          </div>

          <div class="bs-row">
            <div class="bs-col">
              <mat-selection-list [(ngModel)]="objScene" (ngModelChange)="objScene_change($event)" [multiple]="false">
                <mat-list-option [value]="'ACTIVOS'"><mat-icon mat-list-icon>drive_folder_upload</mat-icon>Activos</mat-list-option>
                <mat-list-option [value]="'VENCIDOS'"><mat-icon mat-list-icon>auto_delete</mat-icon>Vencidos</mat-list-option>
              </mat-selection-list>
            </div>
          </div>
        </div>

      </div>

      <div class="bs-viewport" [hidden]="objScene[0] != 'ACTIVOS'">

        <div class="bs-row" *ngIf="lstUsuarioFicherosPendientes?.length > 0">
          <div class="bs-col">
            <div class="section-title"><span> Documentos pendientes</span></div>
          </div>
        </div>



        <div class="datatable-responsive layout">

          <table class="datatable">
            <tbody>
              <tr *ngFor="let itemUsuarioFicheroPendiente of lstUsuarioFicherosPendientes; let index = index;">
                <td  class="text-center" [style.width.px]="30" >
                  <span *ngIf="itemUsuarioFicheroPendiente.ready" class="material-icons ready">task_alt</span>
                </td>
                <td class="text-left">{{itemUsuarioFicheroPendiente.usuarioFicheroTipo}}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="bs-content">
          <div class="bs-row">
            <div class="bs-col">
              <div [style.width.px]="240">
                <comp-zone-uploader [hidden]="objLoader.visible" #compZoneUploader [height]="40" [config]="valueConfiguracion"></comp-zone-uploader>
              </div>
            </div>
          </div>
        </div>


        <div class="datatable-responsive layout" *ngIf="compZoneUploader.files.length > 0">

          <table class="datatable">
            <thead>
              <tr>
                <th [style.width.px]="30" class="text-center"></th>
                <th [style.width.px]="40">Acc.</th>
                <th>Nombre *</th>
                <th [style.width.px]="80">Tamaño</th>
                <th [style.width.px]="200">Tipo documento *</th>
                <th colspan="2" [style.width.px]="260">Vigencia</th>
              </tr>
            </thead>

            <tbody>
              <tr app-usuarios-ficheros-file-item #compFileItem *ngFor="let itemFile of compZoneUploader.files; let index = index;" [itemFile]="itemFile" [index]="index"
                  [usuariosFicherosTipos]="lstUsuarioFicherosTipos"
                  (onDelete)="compUsuarioFicheroFileItem_deleteEvent($event)"
                  (onChange)="compUsuarioFicheroFileItem_changeEvent()"
                  (onInit)="compUsuarioFicheroFileItem_initEvent($event)"
              ></tr>
            </tbody>
            <tfoot>
            <tr>
              <td></td>
              <td [style.paddingLeft.px]="10" colspan="2" ><span>Tamaño total:</span></td>
              <td class="footer-file-size" ><span>{{fileSizeUploader | FileSize}}</span></td>
            </tr>
            </tfoot>
          </table>
        </div>

        <div class="bs-content" *ngIf="compZoneUploader.files.length > 0">
          <div class="bs-row">
            <div class="bs-col">
              <div class="mat-layout-actions">
                <button class="secundary" type="button" (click)="btnSubirFicheros_clickEvent()">Subir documentos</button>
              </div>
            </div>
          </div>
        </div>


        <div class="bs-row">
          <div class="bs-col">
            <div class="section-title"><span>Documentos activos</span></div>
          </div>
        </div>

        <div class="datatable-responsive layout">

          <div class="options-bar">

            <div class="option">
              <button type="button" [disabled]="!this.boolChkSomeVigentes" (click)="btnVigentesDescargarSeleccionados_clickEvent()" matTooltip="Descargar seleccionados en zip">
                <span class="material-icons-outlined">file_download</span>
              </button>
            </div>

            <div class="option">
              <button type="button" [disabled]="!this.boolChkSomeVigentes" (click)="btnVigentesEliminarSeleccionados_clickEvent()" matTooltip="Eliminar seleccionados">
                <span class="material-icons">clear</span>
              </button>
            </div>

            <div class="option">
              <button type="button" [disabled]="!this.boolChkSomeVigentes || !objPrivilegios.USUARIOS_SEND_EMAIL" (click)="btnEnviarCorreo_clickEvent()" matTooltip="Enviar por correo electrónico">
                <span class="material-icons-outlined">email</span>
              </button>
            </div>
          </div>

          <!-- TABLA DE VIGENTES -->

          <table #dataTable class="datatable">
            <thead>
              <tr>
                <th [style.width.px]="40" class="text-center"><mat-checkbox [checked]="boolChkVigentes" (change)="chkUsuariosFicherosVigentes_changeEvent($event.checked)"></mat-checkbox></th>
                <th [style.width.px]="40">No.</th>
                <th>Nombre</th>
                <th colspan="2" [style.width.px]="200">Tipo documento</th>
                <th [style.width.px]="80">Tamaño</th>
                <th colspan="2" [style.width.px]="260">Vigencia</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let itemUsuarioFichero of lstUsuarioFicherosVigentes; let index = index;">
                <td class="text-center"><mat-checkbox [(ngModel)]="lstUsuarioFicherosVigentes[index].checked" (ngModelChange)="chkItemUsuarioFicheroVigente_changeEvent(index)"></mat-checkbox></td>
                <td class="text-center">{{(index + 1)}}</td>
                <td>
                  <div class="item-menu-context">
                    <div class="text-column fichero-link text-ellipsis" [style.width.px]="maxWidth" (click)="viewFile_clickEvente(itemUsuarioFichero)">{{itemUsuarioFichero.nombre}}</div>

                    <div class="spacer"></div>

                    <button class="datatable-button menu-contextual" [matMenuTriggerFor]="menuContextual">
                      <mat-icon class="datatable-button-icon">more_vert</mat-icon>
                    </button>
                    <mat-menu #menuContextual="matMenu" class="itemMenu">
                      <button mat-menu-item (click)="btnUsuarioFicheroDownload_clickEvent(itemUsuarioFichero)">
                        <mat-icon class="material-icons-outlined">file_download</mat-icon>
                        <span>Descargas</span>
                      </button>

                      <button mat-menu-item (click)="viewFile_clickEvente(itemUsuarioFichero)">
                        <mat-icon class="material-icons-outlined">preview</mat-icon>
                        <span>Visualizar</span>
                      </button>

                      <div class="separator"></div>

                      <button mat-menu-item (click)="btnUsuarioFicheroDelete_clickEvent(itemUsuarioFichero)">
                        <mat-icon class="material-icons-outlined">delete</mat-icon>
                        <span>Eliminar</span>
                      </button>
                    </mat-menu>
                  </div>
                </td>
                <td style="text-align: center; width: 25px"><span class="material-icons" *ngIf="itemUsuarioFichero?.usuarioFicheroTipo?.modificadorAccesoRead?.idModificadorAcceso === 1" style="font-size: 12px"> lock </span></td>
                <td>{{(Globals.exist(itemUsuarioFichero?.usuarioFicheroTipo?.idUsuarioFicheroTipo))? itemUsuarioFichero.usuarioFicheroTipo?.usuarioFicheroTipo : 'Otros'}}</td>
                <td class="text-right" >{{itemUsuarioFichero.tamano | FileSize}}</td>
                <td class="text-center" [style.width.px]="130">{{itemUsuarioFichero.vigenciaFechaInicioNatural}}</td>
                <td class="text-center" [style.width.px]="130">{{itemUsuarioFichero.vigenciaFechaFinNatural}}</td>
              </tr>
              <tr>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td [style.paddingLeft.px]="10" colspan="4" ><span>{{numberItemsVigentes}}&nbsp;Elementos seleccionados:</span></td>
                <td class="footer-file-size" ><span>{{fileSizeVigente | FileSize}}</span></td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>

      <div class="bs-viewport" [hidden]="objScene[0] != 'VENCIDOS'">

        <div class="bs-row">
          <div class="bs-col">
            <div class="section-title"><span>Documentos vencidos</span></div>
          </div>
        </div>

        <div class="options-bar">

          <div class="option">
            <button type="button" [disabled]="!this.boolChkSomeVencidos" (click)="btnVencidosDescargarSeleccionados_clickEvent()" matTooltip="Descargar seleccionados en zip">
              <span class="material-icons-outlined">file_download</span>
            </button>
          </div>

          <div class="option">
            <button type="button" [disabled]="!this.boolChkSomeVencidos" (click)="btnVencidosEliminarSeleccionados_clickEvent()" matTooltip="Eliminar seleccionados">
              <span class="material-icons">clear</span>
            </button>
          </div>
        </div>

        <!-- TABLA DE VENCIDOS -->

        <div class="datatable-responsive layout">
          <table class="datatable">
            <thead>
              <tr>
                <th [style.width.px]="40" class="text-center"><mat-checkbox [checked]="boolChkVencidos" (change)="chkUsuariosFicherosVencidos_changeEvent($event.checked)"></mat-checkbox></th>
                <th [style.width.px]="60">No.</th>
                <th>Nombre</th>
                <th [style.width.px]="200">Tipo documento</th>
                <th [style.width.px]="80">Tamaño</th>
                <th colspan="2" [style.width.px]="260">Vigencia</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let itemUsuarioFichero of lstUsuarioFicherosVencidos; let index = index;">
                <td class="text-center"><mat-checkbox [(ngModel)]="lstUsuarioFicherosVencidos[index].checked" (ngModelChange)="chkItemUsuarioFicheroVencido_changeEvent(index)"></mat-checkbox></td>
                <td class="text-center">{{(index + 1)}}</td>
                <td>
                  <div class="item-menu-context">
                    <div class="text-column fichero-link text-ellipsis" [style.width.px]="maxWidth" (click)="viewFile_clickEvente(itemUsuarioFichero)">{{itemUsuarioFichero.nombre}}</div>

                    <div class="spacer"></div>

                    <button class="datatable-button menu-contextual" [matMenuTriggerFor]="menuContextual">
                      <mat-icon class="datatable-button-icon">more_vert</mat-icon>
                    </button>
                    <mat-menu #menuContextual="matMenu" class="itemMenu">
                      <button mat-menu-item (click)="btnUsuarioFicheroDownload_clickEvent(itemUsuarioFichero)">
                        <mat-icon class="material-icons-outlined">file_download</mat-icon>
                        <span>Descargas</span>
                      </button>

                      <button mat-menu-item (click)="viewFile_clickEvente(itemUsuarioFichero)">
                        <mat-icon class="material-icons-outlined">preview</mat-icon>
                        <span>Visualizar</span>
                      </button>

                      <div class="separator"></div>

                      <button mat-menu-item (click)="btnUsuarioFicheroDelete_clickEvent(itemUsuarioFichero)">
                        <mat-icon class="material-icons-outlined">delete</mat-icon>
                        <span>Eliminar</span>
                      </button>
                    </mat-menu>
                  </div>
                </td>
                <td>{{(Globals.exist(itemUsuarioFichero?.usuarioFicheroTipo?.idUsuarioFicheroTipo))? itemUsuarioFichero.usuarioFicheroTipo?.usuarioFicheroTipo : 'Otros'}}</td>
                <td class="text-right" >{{itemUsuarioFichero.tamano | FileSize}}</td>
                <td class="text-center" [style.width.px]="130">{{itemUsuarioFichero.vigenciaFechaInicioNatural}}</td>
                <td class="text-center" [style.width.px]="130">{{itemUsuarioFichero.vigenciaFechaFinNatural}}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td [style.paddingLeft.px]="10" colspan="4" ><span>{{numberItemsVencidos}}&nbsp;Elementos seleccionados:</span></td>
                <td class="footer-file-size" ><span>{{fileSizeVencidos | FileSize}}</span></td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>

    </div>
  </div>

  <div class="mat-dialog-loader mat-dialog-no-actions">
    <mat-progress-bar class="loader" *ngIf="objLoader.visible" color="accent" mode="indeterminate"></mat-progress-bar>
  </div>
</div>
