import {AfterViewInit, Component, EventEmitter, Inject, OnInit, Output, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {AuthService} from "../../../../services/auth.service";
import {UsuariosService} from "../../../../services/usuarios.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {FormOptimizer} from "../../../../libraries/formOptimizer";
import {AlertComponent} from "../../../../components/alert/alert.component";
import {Globals} from "../../../../libraries/globals";
import {SuccessComponent} from "../../../../components/success/success.component";
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatChipInputEvent} from '@angular/material/chips';
import {ApplicationConfig} from "../../../../libraries/application-config";
import {SettingsMailIndexComponent} from "../setting_email/index.component";
import {InputAutocompleteComponent} from "../../../../components/input-autocomplete/input-autocomplete.component";
import {map, startWith} from "rxjs/operators";
import {Observable} from "rxjs/Observable";

export interface Mails {
  name: string;
}

@Component({
  selector: 'app-send-email-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class SendEmailIndexComponent implements OnInit, AfterViewInit {

  @Output('onClose') onClose : EventEmitter<SendEmailIndexComponent> = new EventEmitter<SendEmailIndexComponent>();
  @ViewChild('txtCliente')          txtCliente          : InputAutocompleteComponent;
  @ViewChild('txtClienteContacto')  txtClienteContacto  : InputAutocompleteComponent;

  public fgUsuarioSendEmail: FormGroup = new FormGroup({
    empresa                : new FormControl('', Validators.required),
    remitente              : new FormControl('', Validators.required),
    destinatario           : new FormControl('', Validators.required),
    asunto                 : new FormControl('', Validators.required),
    mensaje                : new FormControl(''),
    clienteNombreComercial : new FormControl(''),
    contactoNombreCompleto : new FormControl('')
  });

  public objApplicationConfig = ApplicationConfig;
  public objUsuarioEnvio              : any;
  public objUsuario                   : any = {};
  public objEmpresa                   : any = {};
  // objUsuarioEmail ES EL USUARIO QUE ENVÍA EL CORREO
  public objUsuarioEmail              : any = {};
  public selectable                   : boolean = true;
  public removable                    : boolean = true;
  public addOnBlur                    : boolean = true;
  public readonly separatorKeysCodes  : number[] = [ENTER, COMMA];
  public lstDestinatarios             : Mails[] = [];
  public lstUsuariosFicherosVigentes  : Array<any> = [];
  public lstEmpresas                  : Array<any> =[];
  public lstUsuariosEmails            : Array<any> =[];
  public isValidEmail                 : boolean = false;
  public numberItems                  : number = 0;
  public fileSize                     : number = 0;
  public lstClientes                  : Array<any> = [];
  public lstClientesContactos         : Array<any> = [];
  public lstClientesContactosFiltered : Observable<any>;
  public boolAutoCompleteCliente      : boolean = false;

  public objLoader = {
    type            : 'loader',
    visible         : false
  }

  public btnConfEmail = {
    type            : 'button',
    disabled        : false
  }

  public inputPanel = {
    type            : 'button',
    disabled        : false
  }

  public chckLstFichero = {
    type            : 'button',
    disabled        : false
  }

  public btnAceptar = {
    type            : 'button',
    disabled        : false
  }

  public btnCancelar = {
    type            : 'button',
    disabled        : false
  }

  public formComponents : Array<any> = [
    this.objLoader,
    this.btnConfEmail,
    this.inputPanel,
    this.chckLstFichero,
    this.btnAceptar,
    this.btnCancelar,
    this.fgUsuarioSendEmail
  ];

  constructor(private objDialogRef: MatDialogRef<SendEmailIndexComponent>,
              private objMatDialog: MatDialog,
              private objAuthService : AuthService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private objUsuariosService : UsuariosService
  ) {
  }

  ngOnInit(): void {
    FormOptimizer.formDisable(this.formComponents);
  }

  ngAfterViewInit() : void {
    setTimeout(() => this.init(), 500);
  }

  public init() : void {
    this.objUsuariosService.infoEmail(this.data.idUsuario)
      .subscribe( objResponse => {
        if (objResponse.action) {
          this.objUsuario = objResponse.result.usuario;

          this.objUsuarioEnvio = objResponse.result.usuarioEnvio;
          this.isValidEmail = this.objUsuarioEnvio.isValidEmail
          this.lstEmpresas = objResponse.result.empresas;
          this.lstUsuariosEmails = objResponse.result.usuariosEmails;
          this.initUsuariosFicherosVigentes(objResponse.result.usuarioFicheros);

          if (Globals.exist(objResponse.result.usuarioEmail)) {
            this.objUsuarioEmail = objResponse.result.usuarioEmail;
            this.fgUsuarioSendEmail.controls['asunto'].setValue(this.objUsuarioEmail.asunto);
            this.fgUsuarioSendEmail.controls['mensaje'].setValue(this.objUsuarioEmail.mensaje);
          }

          this.fgUsuarioSendEmail.controls['remitente'].setValue(this.objUsuarioEnvio.sendCorreo);
          this.fgUsuarioSendEmail.controls['destinatario'].setValue('.');
          FormOptimizer.formEnable(this.formComponents);
          this.fgUsuarioSendEmail.controls['remitente'].disable();


          if (Globals.exist(this.objUsuario.empresa)) {
            this.fgUsuarioSendEmail.controls['empresa'].setValue(this.objUsuario.empresa.idEmpresa);
          }
        } else {
          this.objMatDialog.open(AlertComponent, Globals.alertConfig({titulo: 'Error de comunicación', mensaje: 'Ocurrió un error de comunicación con el servidor.', autoCloseDelay: 3000}));
          FormOptimizer.formEnable(this.formComponents);
        }
      }, error => {
        console.log(error);
        FormOptimizer.formEnable(this.formComponents);
        this.fgUsuarioSendEmail.controls['remitente'].disable();
      });
  }

  public initUsuariosFicherosVigentes(lstUsuariosFicherosVigentes : Array<any>) : void {
    this.lstUsuariosFicherosVigentes = [];
    lstUsuariosFicherosVigentes.forEach( itemUsuarioFicheroVigente => {
      itemUsuarioFicheroVigente['checked'] = false;
      if(Globals.exist(this.data.ficheros)) {
        let ficheros : Array<any> = this.data.ficheros;
        ficheros.forEach(itemFicheros => {
          if(itemUsuarioFicheroVigente.idUsuarioFichero == itemFicheros.idUsuarioFichero) {
            itemUsuarioFicheroVigente['checked'] = true;
            this.fileSize += itemUsuarioFicheroVigente.tamano;
            this.numberItems ++;
          }
        });
      }
      this.lstUsuariosFicherosVigentes.push(itemUsuarioFicheroVigente);
    });
  }

  public formUsuarioEnviarCorreo_submitEvent() : void {
    if (this.isValidEmail) {
      (<any>Object).values(this.fgUsuarioSendEmail.controls).forEach((itemControl: FormControl) => {
        itemControl.markAsTouched();
      });
      if (this.fgUsuarioSendEmail.valid) {
        let objEmail = new FormData();
        objEmail.append('idUsuarioEnvio'  , FormOptimizer.formDataNumber(this.objUsuarioEnvio.idUsuario));
        objEmail.append('idUsuario'       , FormOptimizer.formDataNumber(this.objUsuario.idUsuario));
        objEmail.append('idUsuarioEmail'  , FormOptimizer.formDataNumber(this.objUsuarioEmail.idUsuarioEmail));
        objEmail.append('idEmpresa'       , FormOptimizer.formDataString(this.fgUsuarioSendEmail.controls['empresa']));
        objEmail.append('asunto'          , FormOptimizer.formDataString(this.fgUsuarioSendEmail.controls['asunto']));
        objEmail.append('mensaje'         , FormOptimizer.formDataString(this.fgUsuarioSendEmail.controls['mensaje']));
        objEmail.append('cliente'         , FormOptimizer.formDataString(this.fgUsuarioSendEmail.controls['clienteNombreComercial']));
        objEmail.append('contacto'        , FormOptimizer.formDataString(this.fgUsuarioSendEmail.controls['contactoNombreCompleto']));

        this.lstDestinatarios.forEach((itemDestinatario, index) => {
          objEmail.append('destinatario' + index, itemDestinatario.name + '');
        });

        let count: number = 0;
        this.lstUsuariosFicherosVigentes.forEach(itemUsuarioFichero => {
          if (itemUsuarioFichero.checked) {
            objEmail.append('idUsuarioFichero' + count, itemUsuarioFichero.idUsuarioFichero + '');
            count++;
          }
        });

        FormOptimizer.formDisable(this.formComponents);
        this.objUsuariosService.sendEmail(objEmail)
          .subscribe(objResponse => {
            FormOptimizer.formEnable(this.formComponents);
            this.fgUsuarioSendEmail.controls['remitente'].disable();
            if (objResponse.action) {
              this.close();
              this.objMatDialog.open(SuccessComponent, Globals.successConfig({titulo: objResponse.title, mensaje: objResponse.message, autoCloseDelay: 3000}));
            } else {
              this.objMatDialog.open(AlertComponent, Globals.alertConfig({titulo: objResponse.title, mensaje: objResponse.message}));
            }
          }, error => {
            this.objMatDialog.open(AlertComponent, Globals.alertConfig({titulo: 'Error de comunicación', mensaje: 'Ocurrió un error de comunicación con el servidor.', autoCloseDelay: 3000}));
            FormOptimizer.formEnable(this.formComponents);
            this.fgUsuarioSendEmail.controls['remitente'].disable();
            this.close();
          });
      } else {
        this.objMatDialog.open(AlertComponent, Globals.confirmConfig({titulo: "Error de formulario", mensaje: "Formulario incompleto.", autoCloseDelay: 3000}));
        FormOptimizer.formEnable(this.formComponents);
        this.fgUsuarioSendEmail.controls['remitente'].disable();
      }
    } else {
      this.objMatDialog.open(AlertComponent, Globals.alertConfig({titulo: 'Correo no valido', mensaje: 'Revise la configuración del correo, no es válida.', autoCloseDelay: 3000}));
      FormOptimizer.formEnable(this.formComponents);
      this.fgUsuarioSendEmail.controls['remitente'].disable();
    }
  }

  public btnAgregarDestinatario_clickEvent(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || "").trim()) {
      this.lstDestinatarios.push({ name: value.trim() });
      this.fgUsuarioSendEmail.controls['destinatario'].setValue('.');
    }

    // Reset the input value
    if (input) {
      input.value = "";
    }
  }

  public btnRemoverDestinatario_clickEvent(lstDestinatarios: Mails): void {
    const index = this.lstDestinatarios.indexOf(lstDestinatarios);

    if (index >= 0) {
      this.lstDestinatarios.splice(index, 1);
    }

    if(this.lstDestinatarios == null)
      this.fgUsuarioSendEmail.controls['destinatario'].setValue('');

  }

  public getEmpresaSelected_selectedEvent(idEmpresa: number) : void {
    this.objEmpresa = this.lstEmpresas.find(itemEmpresa => itemEmpresa.idEmpresa == idEmpresa);
    this.objUsuarioEmail = this.lstUsuariosEmails.find(itemUsuarioEmail => itemUsuarioEmail.empresa.idEmpresa == idEmpresa);
    let strAsunto : string = this.objUsuarioEmail.asunto;
    strAsunto = strAsunto.replace('_EMPRESA_', this.objEmpresa.nombreComercial);
    strAsunto = strAsunto.replace('_USUARIO_NOMBRE_COMPLETO', this.objUsuario.nombreCompleto);
    this.fgUsuarioSendEmail.controls['asunto'].setValue(strAsunto);
    this.fgUsuarioSendEmail.controls['mensaje'].setValue(this.objUsuarioEmail.mensaje);
  }

  public txtClientes_keyUpEvent( event : any) : void {
    let strSearch : string = this.fgUsuarioSendEmail.controls['clienteNombreComercial'].value;

    if(event.keyCode == 13 && !this.boolAutoCompleteCliente) {
      this.boolAutoCompleteCliente = true;
      this.txtCliente.loading = true;
      this.objUsuariosService.findClientes(strSearch)
        .subscribe( objResponse => {
          this.lstClientes = objResponse.result.clientes;
          this.txtCliente.loading = false;
        }, error => {
          this.txtCliente.loading = false;
        });
    } else if(!(event.keyCode > 36 && event.keyCode < 41) && event.keyCode != 13) {
      this.txtCliente_clearEvent();
    } else if(strSearch == '') {
      this.txtCliente_clearEvent();
    }
  }

  public txtCliente_OnFocusOutEvent() : void {
    if(!this.boolAutoCompleteCliente) {
      this.txtCliente_clearEvent();
      this.fgUsuarioSendEmail.controls['clienteNombreComercial'].setValue("");
    } else if(this.txtCliente.objValue == null) {
      this.fgUsuarioSendEmail.controls['clienteNombreComercial'].setValue("");
    }
  }

  public txtCliente_clearEvent() : void {
    this.boolAutoCompleteCliente = false;
    this.txtCliente.objValue = null;
    this.txtClienteContacto.objValue = null;
    this.fgUsuarioSendEmail.controls['contactoNombreCompleto'].setValue('');
    this.fgUsuarioSendEmail.controls['contactoNombreCompleto'].disable();
    this.lstClientes = [];
    this.lstClientesContactos = [];
  }

  public txtClienteContactos_keyUpEvent( event : any) : void {
    this.txtClienteContacto.objValue = null;
  }

  public getClienteSelected_selectedEvent(idCliente: number) : void {
    this.fgUsuarioSendEmail.controls['contactoNombreCompleto'].enable();
    let objCliente = this.lstClientes.find(itemCliente => itemCliente.idCliente == idCliente);
    this.txtCliente.objValue = objCliente;
    this.fgUsuarioSendEmail.controls['clienteNombreComercial'].setValue(objCliente.nombreComercial);
    this.lstClientesContactos = objCliente.contactos;
    this.lstClientesContactosFiltered = this.fgUsuarioSendEmail.controls['contactoNombreCompleto'].valueChanges.pipe(
      startWith(''),
      map(value => {
        const filterValue = value.toLowerCase();
        return this.lstClientesContactos.filter(itemClienteContacto => itemClienteContacto.nombreCompleto.toLowerCase().includes(filterValue));
      })
    );
  }

  public getClienteContactoSelected_selectedEvent(value: string) : void {
    let objClienteContacto = this.lstClientesContactos.find(itemClienteContacto => itemClienteContacto.nombreCompleto.includes(value));
    this.txtClienteContacto.objValue = objClienteContacto
    this.fgUsuarioSendEmail.controls['contactoNombreCompleto'].setValue(objClienteContacto.nombreCompleto);
  }

  public btnSettingSendEmail_clickEvent() {
    let objDialog = this.objMatDialog.open(SettingsMailIndexComponent, {
      width         : '400px',
      maxWidth      : '600px',
      height        : '650px',
      maxHeight     : '98%',
      data          : this.objUsuarioEnvio,
      disableClose  : true
    });

    objDialog.componentInstance.onClose.subscribe( ( itemSettingsMailIndexComponent : SettingsMailIndexComponent) => {
      this.init();
    });
  }

  public chkItemUsuarioFicheroVigente_changeEvent( index : number ) : void {
    if(this.lstUsuariosFicherosVigentes[index].checked) {
      this.fileSize += this.lstUsuariosFicherosVigentes[index].tamano;
      this.numberItems ++;
    }
    else {
      this.fileSize -= this.lstUsuariosFicherosVigentes[index].tamano;
      this.numberItems --;
    }
  }

  public close() : void {
    this.objDialogRef.close();
    this.onClose.emit(this);
  }
}
