import { FormControl, FormGroup } from '@angular/forms';
import { Globals } from './globals';
import {isMoment} from "moment";


export  class FormOptimizer {

  public static formDataString( param : any ) : string {
    let objReturn : string = "";

    if(Globals.exist(param) && Globals.exist(param.value) ) {
      objReturn = param.value;
    }

    return objReturn;
  }

  public static formDataBoolean( param : any ) : string {
    let boolResult : boolean = false;
    let objReturn : string = 'false';

    if(Globals.exist(param) && Globals.exist(param.value) ) {
      boolResult = param.value;
    }

    if( boolResult ) {
      objReturn = 'true';
    }



    return objReturn;
  }

  public static formDataNumber( param : any ) : string {
    let numericValue : number = 0;
    let objReturn = '0';
    if( param instanceof FormControl ) {
      if(Globals.exist(param) && Globals.exist(param.value) && Globals.isNumeric(param.value) ) {
        numericValue = param.value;
        objReturn = numericValue.toString();
      }
    } else if( Globals.isNumeric(param) ) {
      numericValue = param;
      objReturn = numericValue.toString();
    }
    return objReturn;
  }

  public static formDataDate(param : any, format: string = 'YYYY-MM-DD') : string {
    let objReturn = '';
    if(Globals.exist(param) && Globals.exist(param.value) && isMoment(param.value) && param.value.isValid() ) {
      objReturn = param.value.format(format);
    }

    return objReturn;
  }

  public static ( param : any ) : string {
    let numericValue : number = 0;
    let objReturn = '0';

    if(Globals.exist(param) && Globals.exist(param.value) ) {
      numericValue = param.value;
      objReturn = numericValue.toString();
    }
    return objReturn;
  }

  public static formDisable( lstComponents : Array<any> ) {
    lstComponents.forEach(itemComponent => {
      if(Globals.exist( itemComponent )) {
        if( itemComponent instanceof FormGroup ) {
          for(let key in itemComponent.controls) {
            itemComponent.controls[key].disable();
          }
        } else if( itemComponent instanceof FormControl ) {
            itemComponent.disable();
        } else if( itemComponent.type == 'button' || itemComponent.type == 'input' || itemComponent.type == 'componentForm' || itemComponent.type == 'select' || itemComponent.type == 'checkbox') {
          if( Globals.exist(itemComponent.disabled) ) {
            itemComponent.disabled = true;
          }
        } else if(itemComponent.type == 'loader') {
          if( Globals.exist(itemComponent.visible) ) {
            itemComponent.visible = true;
          }
        }
      }
    });
  }

  public static formEnable( lstComponents : Array<any> ) {
    lstComponents.forEach(itemComponent => {
      if(Globals.exist( itemComponent )) {
        if( itemComponent instanceof FormGroup ) {
          for(let key in itemComponent.controls) {
            itemComponent.controls[key].enable();
          }
        } else if( itemComponent instanceof FormControl ) {
          itemComponent.enable();
        } else if( itemComponent.type == 'button' || itemComponent.type == 'input' || itemComponent.type == 'componentForm' || itemComponent.type == 'select' || itemComponent.type == 'checkbox') {
          if( Globals.exist(itemComponent.disabled) ) {
            itemComponent.disabled = false;
          }
        } else if(itemComponent.type == 'loader') {
          if( Globals.exist(itemComponent.visible) ) {
            itemComponent.visible = false;
          }
        }
      }
    });
  }





}
