import * as Stomp from 'stompjs';
import * as SockJS from 'sockjs-client';
import { WebSocketShareService } from './websocketshare.service';
import { Injectable } from '@angular/core';
import {ApplicationConfig} from "./application-config";
import { Sessions } from './sessions';
import { AuthService } from '../services/auth.service';

@Injectable()
export class WebSocketAPI {
  webSocketEndPoint: string = ApplicationConfig.ENDPOINT + 'ws';
  stompClient: any;

  constructor(private websocketShare: WebSocketShareService,
              private objAuthService : AuthService){

  }
  _connect() : void {
    let ws = new SockJS(this.webSocketEndPoint);
    this.stompClient = Stomp.over(ws);
    const _this = this;
    _this.stompClient.connect(Sessions.headerSession(this.objAuthService.access_token), (frame : any) => {
      _this.stompClient.subscribe("/topic/cotizacion", (sdkEvent : any) => {
        _this.onCotizacionChanged(JSON.parse(sdkEvent.body));
      }, Sessions.headerSession(this.objAuthService.access_token));

      _this.stompClient.subscribe("/topic/orden-pedido", (sdkEvent : any) => {
        _this.onOrdenPedidoChanged(JSON.parse(sdkEvent.body));
      }, Sessions.headerSession(this.objAuthService.access_token));

      _this.stompClient.subscribe("/topic/orden-servicio", (sdkEvent : any) => {
        _this.onOrdenServicioChanged(JSON.parse(sdkEvent.body));
      });

      _this.stompClient.subscribe("/topic/orden-mantenimiento", (sdkEvent : any) => {
        _this.onOrdenMantenimientoChanged(JSON.parse(sdkEvent.body));
      });
      //_this.stompClient.reconnect_delay = 2000;
    }, this.errorCallBack);
  };

  _connectMantenimimientoLog() : void {
    let ws = new SockJS(this.webSocketEndPoint);
    this.stompClient = Stomp.over(ws);
    const _this = this;
    _this.stompClient.connect({}, (frame : any) => {
      _this.stompClient.subscribe("/topic/mantenimiento-log", (sdkEvent : any) => {
        _this.onMantenimientoLogChanged(JSON.parse(sdkEvent.body));
      });
      //_this.stompClient.reconnect_delay = 2000;
    }, this.errorMantenimientoLogCallBack);
  };

  _connectMantenimimientoTallerLog() : void {
    let ws = new SockJS(this.webSocketEndPoint);
    this.stompClient = Stomp.over(ws);
    const _this = this;
    _this.stompClient.connect({}, (frame : any) => {
      _this.stompClient.subscribe("/topic/mantenimiento-taller-log", (sdkEvent : any) => {
        _this.onMantenimientoTallerLogChanged(JSON.parse(sdkEvent.body));
      });
      //_this.stompClient.reconnect_delay = 2000;
    }, this.errorMantenimientoTallerLogCallBack);
  };

  _disconnect() : void {
    if (this.stompClient !== null) {
      this.stompClient.disconnect();
    }
    console.log("Disconnected");
  }

  // on error, schedule a reconnection attempt
  errorCallBack(error : any) : void {
    console.log("errorCallBack -> " + error);
    setTimeout(() => {
      this._connect();
    }, 5000);
  }

  errorMantenimientoLogCallBack(error : any) : void {
    console.log("errorCallBack -> " + error);
    setTimeout(() => {
      this._connectMantenimimientoLog();
    }, 5000);
  }

  errorMantenimientoTallerLogCallBack(error : any) : void {
    console.log("errorCallBack -> " + error);
    setTimeout(() => {
      this._connectMantenimimientoTallerLog();
    }, 5000);
  }

  onCotizacionChanged(message : any) : void {
    this.websocketShare.onCotizacionNewValueReceive(message);
  }

  onOrdenPedidoChanged(message : any) : void {
    this.websocketShare.onOrdenPedidoNewValueReceive(message);
  }

  onOrdenServicioChanged(message : any) : void {
    this.websocketShare.onOrdenServicioNewValueReceive(message);
  }

  onOrdenMantenimientoChanged(message : any) : void {
    this.websocketShare.onOrdenMantenimientoNewValueReceive(message);
  }

  onMantenimientoLogChanged(message : any) : void {
    this.websocketShare.onMantenimientoLogNewValueReceive(message);
  }

  onMantenimientoTallerLogChanged(message : any) : void {
    this.websocketShare.onMantenimientoTallerLogNewValueReceive(message);
  }
}
