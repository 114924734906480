export class Globals {

  public static ALERT_CONFIG : any = {
    width         : '96%',
    maxWidth      : '600px',
    height        : '96%',
    maxHeight     : '260px',
    disableClose  : false
  }

  public static CONFIRM_CONFIG : any = {
    width         : '96%',
    maxWidth      : '600px',
    height        : '96%',
    maxHeight     : '260px',
    disableClose  : true
  }

  public static SUCCESS_CONFIG : any = {
    width         : '96%',
    maxWidth      : '600px',
    height        : '96%',
    maxHeight     : '260px',
    disableClose  : true
  }

  public static TOATS_SUCCESS_CONFIG : any = {
    width         : '96%',
    maxWidth      : '600px',
    height        : '96%',
    maxHeight     : '55px',
    panelClass    : 'toats-notification-animated',
    disableClose  : true
  }

  public static exist( value : any ) : boolean {
    return ( value != undefined && value != null );
  }

  public static isStringNotEmpty(argObject : string) : boolean {
    let objReturn : boolean = false;
    if( Globals.exist(argObject) && argObject.toString().trim() != "") {
      objReturn = true;
    }
    return objReturn;
  }

  public static isNumeric(argObject : any) : boolean {
    let objReturn : boolean = false;

    if( Globals.exist(argObject) && !isNaN(Number(argObject)) && Number(argObject).toString() == argObject.toString() ) {
      objReturn = true;
    }
    return objReturn;
  }

  public static isId(argObject : any) : boolean {
    let objReturn : boolean = false;

    if( Globals.isNumeric(argObject) && argObject > 0 ) {
      objReturn = true;
    }
    return objReturn;
  }

  public static alertConfig(data : any = null, configs : any = null) {
    let objConfig : any = Globals.ALERT_CONFIG;
    if(data != null) {
      objConfig.data = data;
    }

    if(configs != null) {
      for(var key in configs)
      {
        objConfig[key] = configs[key];
      }
    }
    return objConfig;
  }

  public static confirmConfig(data : any = null, configs : any = null) {
    let objConfig : any = Globals.CONFIRM_CONFIG;
    if(data != null) {
      objConfig.data = data;
    }

    if(configs != null) {
      for(var key in configs)
      {
        objConfig[key] = configs[key];
      }
    }
    return objConfig;
  }

  public static successConfig(data : any = null, configs : any = null) {
    let objConfig : any = Globals.SUCCESS_CONFIG;
    if(data != null) {
      objConfig.data = data;
    }

    if(configs != null) {
      for(var key in configs)
      {
        objConfig[key] = configs[key];
      }
    }
    return objConfig;
  }

  public static toatsSuccessConfig(data : any = null, configs : any = null) {
    let objConfig : any = Globals.TOATS_SUCCESS_CONFIG;
    if(data != null) {
      objConfig.data = data;
    }

    if(configs != null) {
      for(var key in configs)
      {
        objConfig[key] = configs[key];
      }
    }
    return objConfig;
  }

  public static uniqueId(): string {
    return Math.random().toString(36).substr(2, 14);
  }

  public static cookieExpiration() : Date {
    let expiredDate : Date  = new Date();
    expiredDate.setDate( expiredDate.getDate() + 7 );

    return expiredDate;
  }


}
