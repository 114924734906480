<div class="file-upload-content">
  <div *ngIf="!isValid()" class="preview">{{title}}</div>
  <div *ngIf="isValid()"  class="preview">{{fileName}}</div>
  <div class="overlay" *ngIf="!disabled"
       [style.backgroundColor]="backgroundColor"
       (click)="btnUpload_clickEvent()">
    <span class="material-icons unselectable">file_upload</span>
  </div>

  <input type="file" #inputFile (change)="file_changeEvent($event)" accept="{{accept}}" />
</div>
