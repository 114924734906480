import {Component, EventEmitter, Inject, Input, OnInit, Output, AfterViewInit} from '@angular/core';
import {Globals} from "../../libraries/globals";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {SuccessComponent} from "../success/success.component";
import {FormOptimizer} from "../../libraries/formOptimizer";
import {AlertComponent} from "../alert/alert.component";
import {UsuariosService} from "../../services/usuarios.service";

@Component({
  selector: 'app-email-status',
  templateUrl: './email-status.component.html',
  styleUrls: ['./email-status.component.scss']
})
export class EmailStatusComponent implements OnInit, AfterViewInit {

  public titulo         : string = '';
  public boolEmail      : string = '';
  public boolPort       : string = '';
  public boolServer     : string = '';
  public boolMessage    : string = '';
  public acceptLabel    : string = 'Aceptar';
  public cancelLabel    : string = 'Cancelar';
  public autoCloseDelay : number = 0;
  public fnClose        : any;
  public fnAccept       : any;
  public fnChange       : Function;
  public fnCancel       : any;

  @Output('onClose') onClose : EventEmitter<EmailStatusComponent> = new EventEmitter<EmailStatusComponent>();
  @Output('onCloseAll') onCloseAll : EventEmitter<EmailStatusComponent> = new EventEmitter<EmailStatusComponent>();
  @Output('onChange') onChange : EventEmitter<EmailStatusComponent> = new EventEmitter<EmailStatusComponent>();

  public objLoader = {
    type            : 'loader',
    visible         : true
  }

  public btnAceptar = {
    type            : 'button',
    disabled        : false
  }

  public btnCancelar = {
    type            : 'button',
    disabled        : false
  }

  public formComponents : Array<any> = [
    this.objLoader,
    this.btnAceptar,
    this.btnCancelar
  ];

  constructor(
    private objMatDialog: MatDialog,
    public objDialogRef: MatDialogRef<EmailStatusComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private objUsuariosService : UsuariosService
  ) {}

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.init());
  }

  public init() : void {
    FormOptimizer.formDisable(this.formComponents);

    this.objUsuariosService.settingSendEmail(this.data)
        .subscribe( objResponse => {
          this.change();
            if (objResponse.action) {
              this.boolPort    = 'true';
              this.boolServer  = 'true';
              this.boolEmail   = 'true';
              this.boolMessage = 'true';
            } else if (objResponse.code == 'OPEN_PORT_SERVER_001' || objResponse.code == 'PORT_BUSY_SERVER_001') {
              this.acceptLabel = '¿Guardar de todos modos?'
              this.boolPort    = 'false';
              this.boolServer  = 'false';
              this.boolEmail   = 'false';
              this.boolMessage = 'false';
            } else if (objResponse.code == 'CONNECT_SERVER') {
              this.acceptLabel = '¿Guardar de todos modos?'
              this.boolPort    = 'true';
              this.boolServer  = 'false';
              this.boolEmail   = 'false';
              this.boolMessage = 'false';
            } else if (objResponse.code == 'LOGIN') {
              this.acceptLabel = '¿Guardar de todos modos?'
              this.boolPort    = 'true';
              this.boolServer  = 'true';
              this.boolEmail   = 'false';
              this.boolMessage = 'false';
            }

          FormOptimizer.formEnable(this.formComponents);
        }, error => {
          this.objMatDialog.open(AlertComponent, Globals.alertConfig({titulo : "Error de comunicación", mensaje : "Ocurrió un error de comunicación con el servidor.", autoCloseDelay : 3000}));
          FormOptimizer.formEnable(this.formComponents);
        });
  }

  public change() : void {
    this.onChange.emit(this);
  }

  public closeAll() : void {
    this.objDialogRef.close();
    this.onCloseAll.emit(this);
  }

  public close() : void {
    this.objDialogRef.close();
    this.onClose.emit(this);
  }

  public btnAceptar_clickEvent() : void {
    if( this.boolMessage == 'false' ) {
      this.data.validEmail = false;
      this.init();
      this.closeAll();
    } else {
      this.closeAll();
    }
  }

  public btnCancelar_clickEvent() : void {
    this.close();
  }

  public btnClose_clickEvent() : void {
    if( this.boolMessage == 'false' ) {
      this.close();
    } else {
      this.closeAll();
    }
  }
}
