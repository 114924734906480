import {AfterViewInit, Component, ElementRef, EventEmitter, Inject, OnInit, Output, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ApplicationConfig} from "../../../../../libraries/application-config";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {OrdenesServiciosUnidadItemComponent} from "./unidad-item/unidad-item.component";
import {FormOptimizer} from "../../../../../libraries/formOptimizer";
import {AlertComponent} from "../../../../../components/alert/alert.component";
import {Globals} from "../../../../../libraries/globals";
import {DatePipe} from "@angular/common";
import {OrdenesServiciosService} from "../../../../../services/ordenes-servicios.service";
import {ConfirmComponent} from "../../../../../components/confirm/confirm.component";

@Component({
  selector: 'app-ordenes-servicios-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class OrdenesServiciosCreateComponent implements OnInit, AfterViewInit {

  @ViewChild   ('inputMinuto')              inputMinuto              : ElementRef;
  @ViewChild   ('inputMinuto_salidaOrigen') inputMinuto_salidaOrigen : ElementRef;
  @Output      ('onClose')                  onClose                  : EventEmitter<OrdenesServiciosCreateComponent> = new EventEmitter<OrdenesServiciosCreateComponent>();
  @ViewChildren('compUnidadesItem')         compUnidadesItem         : QueryList<OrdenesServiciosUnidadItemComponent>;

  public fgOrdenServicioProgramacion: FormGroup = new FormGroup({
    fechaDesde  : new FormControl(''),
    fechaHasta  : new FormControl(''),
    objetivo    : new FormControl(''),
    descripcion : new FormControl('')
  });

  public fgOrdenServicioTiemposEjecucion: FormGroup = new FormGroup({
    programadaFecha       : new FormControl(''),
    programadaHora        : new FormControl(''),
    salidaOrigenFecha     : new FormControl('', Validators.required),
    salidaOrigenHora      : new FormControl('', Validators.required),
    salidaOrigenOdometro  : new FormControl(''),
    salidaOrigenHorometro : new FormControl(''),
  });

  public fgOrdenServicioDirecciones : FormGroup = new FormGroup({
    origen  : new FormControl(''),
    destino : new FormControl(''),
  });

  public objApplicationConfig                        = ApplicationConfig;
  public objOrdenPedido                              : any = {}
  public objOrdenServicio                            : any = {}
  public objCotizacion                               : any = {}
  public lstCotizacionPartidas                       : Array<any> = [];
  public lstUnidades_Usuarios                        : Array<any> = [{}];
  public objScene                                    : any = ["CLIENTE"];
  public folio                                       : string = '';
  public boolChkOrdenServicioUnidad                  : boolean = false;
  public boolChkSomeOrdenServicioUnidad              : boolean = false;
  public boolChkContratoPrestacion                   : boolean = true;
  public boolDynamicChkContratoPrestacion            : boolean = true;
  public hasPrincipal                                : boolean = false;
  public boolHorometro                               : boolean = false;
  public boolOdometro                                : boolean = false;
  public lstOrdenesServiciosMiembros_UsuariosPuestos : Array<any> = [{}];

  public objLoader = {
    type            : 'loader',
    visible         : false
  };

  public objChkBox = {
    type     : 'checkbox',
    disabled : false
  };

  public btnOptionBar = {
    type            : 'button',
    disabled        : false
  };

  public btnAccion = {
    type            : 'button',
    disabled        : false
  };

  public btnAceptar = {
    type            : 'button',
    disabled        : false
  };

  public btnCancelar = {
    type            : 'button',
    disabled        : false
  };

  public formComponents: Array<any> = [
    this.fgOrdenServicioProgramacion,
    this.fgOrdenServicioTiemposEjecucion,
    this.btnOptionBar,
    this.btnAccion,
    this.objChkBox,
    this.objLoader,
    this.btnAceptar,
    this.btnCancelar
  ];

  constructor(
    private objOrdenesServiciosService : OrdenesServiciosService,
    private objDialogRef: MatDialogRef<OrdenesServiciosCreateComponent>,
    private objMatDialog: MatDialog,
    private objDatePipe : DatePipe,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  ngOnInit(): void {
    FormOptimizer.formDisable(this.formComponents);
  }

  ngAfterViewInit(): void {
    setTimeout( ()=> this.init() );
  }

  public init() : void {
      if(Globals.exist(this.data.folio)) this.folio = this.data.folio + ' - ';
      this.objOrdenesServiciosService.create( this.data.idOrdenPedido )
          .subscribe( objResponse=> {
              this.objOrdenPedido = objResponse.result?.ordenPedido;
              this.objCotizacion  = objResponse.result?.cotizacion;
              this.lstCotizacionPartidas = this.objCotizacion?.partidasAprobadas;
              this.lstOrdenesServiciosMiembros_UsuariosPuestos = objResponse.result?.ordenesServiciosMiembros_UsuariosPuestos;

              FormOptimizer.formEnable(this.formComponents);
          }, error => {
              FormOptimizer.formEnable(this.formComponents);
              console.log(error);
          });
  }

  public formOrdenServicioCreate_submitEvent(): void {
    (<any>Object).values(this.fgOrdenServicioTiemposEjecucion.controls).forEach( (itemControl : FormControl) => itemControl.markAsTouched());
    (<any>Object).values(this.fgOrdenServicioProgramacion.controls).forEach( (itemControl : FormControl) => itemControl.markAsTouched());

    let validateUnidades      : boolean = this.compUnidadesItem.toArray().length > 0;
    let validateFormOperadores: boolean = true;
    let validateFormUnidadPrincipal : boolean = true;

    this.compUnidadesItem.toArray().forEach( itemMiembroItem => {
      if (!itemMiembroItem.validateOperadores()) validateFormOperadores = false;
      if (!itemMiembroItem.validateUnidadPrincipal()) validateFormUnidadPrincipal = false;
    });

    if(this.fgOrdenServicioProgramacion.valid) {
      if(this.fgOrdenServicioTiemposEjecucion.valid) {
          if(validateUnidades) {
            if (validateFormUnidadPrincipal) {
              if(validateFormOperadores) {
                let validateOperadores: boolean = this.compUnidadesItem.toArray().some((itemOrdenServicioUnidad: any) => itemOrdenServicioUnidad.lstUnidad_Usuarios.length > 0);
                if (validateOperadores) {
                  this.fnProcessCreate();
                } else {
                  this.objMatDialog.open(ConfirmComponent, Globals.successConfig({titulo: 'Nueva orden de servicio', mensaje: 'No tiene operadores agregados, ¿Desea guarda de todas maneras?', fnAccept: () => {
                      this.fnProcessCreate();
                    }}));
                }
              } else {
                this.objMatDialog.open(AlertComponent, Globals.confirmConfig({titulo : 'Error de registro de unidad', mensaje : 'La información de las unidades no está completa, debe llenar los campos obligatorios'}));
                this.objScene = ["OPERACION"];
              }
            } else {
              this.objMatDialog.open(AlertComponent, Globals.confirmConfig({titulo : 'Error de registro de unidad', mensaje : 'No ha seleccionado una unidad principal, dene tener una obligatoriamente'}));
              this.objScene = ["OPERACION"];
            }
          } else {
              this.objMatDialog.open(ConfirmComponent, Globals.successConfig({titulo: 'Nueva orden de servicio', mensaje: 'No tiene miembros agregados\n¿Desea guarda de todas maneras?', fnAccept: () => {
                      this.fnProcessCreate();
                  }}));
          }
      } else {
        this.objMatDialog.open(AlertComponent, Globals.confirmConfig({titulo : 'Error de formulario', mensaje : 'Los datos de la operación no están completos, complete la información.'}));
        this.objScene = ["OPERACION"];
      }
    } else {
      this.objMatDialog.open(AlertComponent, Globals.confirmConfig({titulo : 'Error de formulario', mensaje : 'Los datos de la programación no están completos, complete la información.'}));
      this.objScene = ["PROGRAMACION"];
    }
  }

  public fnProcessCreate() : void {

    let objOrdenServicioStore : any = {
      idOrdenPedido         : FormOptimizer.formDataNumber( this.data.idOrdenPedido ),
      duracionDesdeFecha    : FormOptimizer.formDataDate(this.fgOrdenServicioProgramacion.controls['fechaDesde']),
      duracionHastaFecha    : FormOptimizer.formDataDate(this.fgOrdenServicioProgramacion.controls['fechaHasta']),
      objetivo              : FormOptimizer.formDataString( this.fgOrdenServicioProgramacion.controls[ 'objetivo'    ]),
      descripcion           : FormOptimizer.formDataString( this.fgOrdenServicioProgramacion.controls[ 'descripcion' ]),
      origen                : FormOptimizer.formDataString( this.fgOrdenServicioDirecciones.controls[ 'origen' ]),
      destino               : FormOptimizer.formDataString( this.fgOrdenServicioDirecciones.controls[ 'destino' ]),
      programadaFecha       : this.formatDateTime(this.fgOrdenServicioTiemposEjecucion.controls['programadaFecha'].value, this.fgOrdenServicioTiemposEjecucion.controls['programadaHora'].value),
      salidaOrigenFecha     : this.formatDateTime(this.fgOrdenServicioTiemposEjecucion.controls['salidaOrigenFecha'].value, this.fgOrdenServicioTiemposEjecucion.controls['salidaOrigenHora'].value),
      salidaOrigenOdometro  : FormOptimizer.formDataNumber(this.fgOrdenServicioTiemposEjecucion.controls['salidaOrigenOdometro']),
      salidaOrigenHorometro : FormOptimizer.formDataNumber(this.fgOrdenServicioTiemposEjecucion.controls['salidaOrigenHorometro']),
      unidades              : this.compUnidadesItem.toArray().map( itemComUnidadItem => itemComUnidadItem.getOrdenServicioUnidad())
    }

    this.compUnidadesItem.toArray().forEach( itemComUnidadItem => itemComUnidadItem.disableFormPartida());
    FormOptimizer.formDisable(this.formComponents);
    this.objOrdenesServiciosService.store(objOrdenServicioStore).subscribe( objResponse => {
      this.objOrdenServicio = objResponse.result.ordenServicio;
      if( objResponse.action) {
        this.close();
      } else
        this.objMatDialog.open(AlertComponent, Globals.alertConfig({titulo: objResponse.title, mensaje: objResponse.message}));
      this.compUnidadesItem.toArray().forEach( itemComUnidadItem => itemComUnidadItem.enableFormPartida());
      FormOptimizer.formEnable(this.formComponents);
    }, error => {
      this.objMatDialog.open(AlertComponent, Globals.alertConfig({titulo: 'Error de comunicación', mensaje: 'Ocurrió un error de comunicación con el servidor.', autoCloseDelay: 3000}));
      this.compUnidadesItem.toArray().forEach( itemComUnidadItem => itemComUnidadItem.enableFormPartida());
      FormOptimizer.formEnable(this.formComponents);
    });

  }

  public chkClientesContactoInitSelected_changeEvent() : void {
    this.boolChkOrdenServicioUnidad = false;
    this.boolChkSomeOrdenServicioUnidad = false;
  }

  public chkDynamicChkContratoPrestacion_changeEvent() {
      // this.boolDynamicChkContratoPrestacion = false;
  }

  public chkOrdenServicioUnidadSelected_changeEvent(event : any ) : void {
    this.boolChkOrdenServicioUnidad = event;
    this.compUnidadesItem.toArray().forEach((itemOrdenServicioUnidad : any) => itemOrdenServicioUnidad.checked = this.boolChkOrdenServicioUnidad);
    this.boolChkSomeOrdenServicioUnidad = this.compUnidadesItem.toArray().some((itemOrdenServicioUnidad : any) => itemOrdenServicioUnidad.checked);
  }

  public chkItemOrdenServicioUnidadSelected_changeEvent() : void {
    this.boolChkOrdenServicioUnidad = this.compUnidadesItem.toArray().every( (itemOrdenServicioUnidad : any) => itemOrdenServicioUnidad.checked );
    this.boolChkSomeOrdenServicioUnidad = this.compUnidadesItem.toArray().some( (itemOrdenServicioUnidad : any) => itemOrdenServicioUnidad.checked );
  }

  public selectUnidadPrincipal(event : boolean) : void {
    this.hasPrincipal = event;
    if (event) {
      this.compUnidadesItem.toArray().forEach( itemMiembro => {
        if (itemMiembro.boolUnidadPrincipal) {
          this.boolOdometro = (Globals.exist(itemMiembro.objUnidad?.hasOdometro) && itemMiembro.objUnidad.hasOdometro);
          this.boolHorometro = (Globals.exist(itemMiembro.objUnidad?.hasHorometro) && itemMiembro.objUnidad.hasHorometro);
          this.fgOrdenServicioTiemposEjecucion.controls['salidaOrigenOdometro'].setValue(itemMiembro.fcOdometro.value);
          this.fgOrdenServicioTiemposEjecucion.controls['salidaOrigenHorometro'].setValue(itemMiembro.fcHoromatro.value);
        }
      });
    } else {
      this.boolOdometro = false;
      this.boolHorometro = false;
      this.fgOrdenServicioTiemposEjecucion.controls['salidaOrigenOdometro'].setValue('');
      this.fgOrdenServicioTiemposEjecucion.controls['salidaOrigenHorometro'].setValue('');
    }
  }

  public selectUnidadOdometro(event : number | null) : void {
    if (this.hasPrincipal) {
      this.fgOrdenServicioTiemposEjecucion.controls['salidaOrigenOdometro'].setValue(event);
    }
  }

  public selectUnidadHorometro(event : number | null) : void {
    if (this.hasPrincipal) {
      this.fgOrdenServicioTiemposEjecucion.controls['salidaOrigenHorometro'].setValue(event);
    }

  }

  public chkDeleteOperador_changeEvent() : void {
      // let validate = this.compUnidadesItem.toArray().every( (itemOrdenServicioUnidad : any) => itemOrdenServicioUnidad.lstUnidad_Usuarios.length < 1 );
      // if(this.boolDynamicChkContratoPrestacion) {
      //     if(validate) {
      //         this.boolChkContratoPrestacion = false;
      //     }
      // }
  }

    public chkAddOperador_changeEvent() : void {
        // if(this.boolDynamicChkContratoPrestacion) {
        //     this.boolChkContratoPrestacion = this.compUnidadesItem.toArray().some((itemOrdenServicioUnidad: any) => itemOrdenServicioUnidad.lstUnidad_Usuarios.length == 1);
        // }
    }

  public btnUnidadAppend_clickEvent() : void {
      this.lstUnidades_Usuarios.push({});
      // if(this.boolDynamicChkContratoPrestacion) {
      //     this.boolChkContratoPrestacion = true;
      // }
  }

  public btnUnidadDelete_clickEvent() : void {
    let arrIndex : Array<any> = [];
    this.compUnidadesItem.toArray().forEach( itemUnidad => {
      if( itemUnidad.checked ) {
        arrIndex.push(itemUnidad.index);
      }
    });
    const reversed = arrIndex.reverse();
    reversed.forEach( index => {
      this.lstUnidades_Usuarios.splice( index, 1);
    });
    this.chkClientesContactoInitSelected_changeEvent();
  }

  public btnPartidasDelete_onDeleteEvent(itemOrdenServicioUnidad : OrdenesServiciosUnidadItemComponent) : void {
      this.lstUnidades_Usuarios.splice(itemOrdenServicioUnidad.index, 1);

      // if(this.boolDynamicChkContratoPrestacion) {
      //     setTimeout( () => {
      //         let validate : boolean = this.compUnidadesItem.toArray().every( (itemOrdenServicioUnidad : any) => itemOrdenServicioUnidad.lstUnidad_Usuarios.length < 1 );
      //         if(validate) this.boolChkContratoPrestacion = false;
      //     });
      // }
  }

  public inputProgramadaTime_keyUpEvent(event : any) : void {
    if(event.target.value != '') {
      this.fgOrdenServicioTiemposEjecucion.controls['programadaHora'].setValue(event.target.value);
    }
  }

  public inputSalidaOrigenTime_keyUpEvent(event : any) : void {
    if(event.target.value != '') {
      this.fgOrdenServicioTiemposEjecucion.controls['salidaOrigenHora'].setValue(event.target.value);
    }
  }

  public formatDateTime(date : string, hora : string) : string {
    let fecha   : string = this.objDatePipe.transform(  date  , 'yyyy-MM-dd') + '';
    fecha = (fecha != '' && fecha != 'null' && fecha != null)? fecha : '0000-00-00'
    hora = this.formatTime(hora);
    return (fecha != '0000-00-00' && hora != '00')? fecha + ' ' + hora : '';
  }

  public formatTime(hora : string) : string {
    let arrayHours : string[] = hora.split(':');

    return ((Globals.exist(arrayHours[0]))? (arrayHours[0].length < 2) ? '0' + arrayHours[0] : arrayHours[0] : '00') + ':' + ((Globals.exist(arrayHours[1]))? (arrayHours[1].length < 2) ? arrayHours[1] + '0' : arrayHours[1] : '00');
  }

  public close(): void {
    this.objDialogRef.close();
    this.onClose.emit(this);
  }


}
