<div class="flex-container" (contextmenu)="$event.preventDefault();">
  <div class="mat-dialog-title" [style.width.%]="100">
    <div class="title"> Evidencias </div>
    <div class="spacer"></div>
    <div class="buttons">
      <button type="button" class="title-button" (click)="close()">
        <mat-icon class="title-button-icon">clear</mat-icon>
      </button>
    </div>
  </div>

  <div class="mat-dialog-body mat-dialog-no-actions">
    <div class="datatable-responsive layout">

      <div class="options-bar">

        <div class="option">
          <button type="button" [disabled]="!this.boolChkSomeVigentes" (click)="btnVigentesDescargarSeleccionados_clickEvent()" matTooltip="Descargar seleccionados en zip">
            <span class="material-icons-outlined">file_download</span>
          </button>
        </div>

        <!-- <div class="spacer"></div> -->

      </div>

      <table class="datatable">
        <thead>
        <tr>
          <th [style.width.px]="40" class="text-center"><mat-checkbox [checked]="boolChkVigentes" (change)="chkClientesFicherosVigentes_changeEvent($event.checked)"></mat-checkbox></th>
          <th [style.width.px]="40">No.</th>
          <th>Nombre</th>
          <th [style.width.px]="80">Tamaño</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let itemClienteFichero of lstGerenciamientosFicheros; let index = index;">
          <td class="text-center"><mat-checkbox [(ngModel)]="itemClienteFichero.checked" (ngModelChange)="chkItemClienteFicheroVigente_changeEvent(index)"></mat-checkbox></td>
          <td class="text-center">{{(index + 1)}}</td>
          <td>
            <div class="item-menu-context">
              <div class="text-column fichero-link text-ellipsis" (click)="viewFile_clickEvente(itemClienteFichero)">{{itemClienteFichero.nombre}}</div>

              <div class="spacer"></div>

              <button class="datatable-button menu-contextual" [matMenuTriggerFor]="menuContextual">
                <mat-icon class="datatable-button-icon">more_vert</mat-icon>
              </button>
              <mat-menu #menuContextual="matMenu" class="itemMenu">
                <button mat-menu-item (click)="btnClienteFicheroDownload_clickEvent(itemClienteFichero)">
                  <mat-icon class="material-icons-outlined">file_download</mat-icon>
                  <span>Descargas</span>
                </button>

                <button mat-menu-item (click)="viewFile_clickEvente(itemClienteFichero)">
                  <mat-icon class="material-icons-outlined">preview</mat-icon>
                  <span>Visualizar</span>
                </button>
              </mat-menu>
            </div>
          </td>
          <td class="text-right" >{{itemClienteFichero.tamano | FileSize}}</td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <td [style.paddingLeft.px]="10" colspan="3" ><span>{{numberItemsVigentes}}&nbsp;Elementos seleccionados:</span></td>
          <td class="footer-file-size" ><span>{{fileSizeVigentes | FileSize}}</span></td>
        </tr>
        </tfoot>
      </table>
    </div>
  </div>

  <div class="mat-dialog-loader mat-dialog-no-actions">
    <mat-progress-bar class="loader" *ngIf="objLoader.visible" color="accent" mode="indeterminate"></mat-progress-bar>
  </div>
</div>
