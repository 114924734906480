<td class="text-center" style="padding: 3px 0 !important">
  <mat-checkbox [disabled]="objChkBox.disabled" [(ngModel)]="checked" (ngModelChange)="chkItemPartidaSelected_changeEvent($event)" ></mat-checkbox>
</td>

<td class="text-center" style="padding: 3px 0 !important">
  <button class="datatable-button" [disabled]="btnAccion.disabled">
    <mat-icon matTooltip="Eliminar" class="datatable-button-icon" (click)="btnPartidaEliminar_clickEvent()">clear</mat-icon>
  </button>
</td>

<td>
  <select class="table-select" [formControl]="fcServicio" [style.width.%]="100">
    <option *ngFor="let itemServicio of lstServicios" [value]="itemServicio.idServicio">{{itemServicio.servicio}}</option>
  </select>
</td>

<td>
  <textarea class="table-input descripcion" type="text" matInput maxlength="256" [formControl]="fcDescripcion"></textarea>
</td>

<td>
  <input class="table-input text-center" type="text" matInput maxlength="256" [formControl]="fcCantidad" autocomplete="off" [class.required]="fcCantidad.hasError('required')  && fcCantidad.touched">
</td>

<td>
  <select class="table-select" [formControl]="fcUnidadMedida" [style.width.%]="100">
    <option *ngFor="let itemUnidadMedida of lstUnidadesMedidas" [value]="itemUnidadMedida.idUnidadMedida">{{itemUnidadMedida.unidadMedida}}</option>
  </select>
</td>

<td>
  <span class="iconSpan" >$</span>
  <input class="table-input text-right precioInput" type="text" mask="separator.2" thousandSeparator="," matInput maxlength="256" [formControl]="fcPrecioUnitario" autocomplete="off" [class.required]="fcPrecioUnitario.hasError('required')  && fcPrecioUnitario.touched">
</td>

<td>
  <select class="table-select" [formControl]="fcMoneda" [style.width.%]="100" (change)="selectMoneda_selectedEvent($event)">
    <option *ngFor="let itemMoneda of lstMonedas" [value]="itemMoneda.idMoneda">{{itemMoneda.codigo}}</option>
  </select>
</td>

<td>
  <span class="iconSpan" >$</span>
  <input class="table-input text-right claseInput" type="text" mask="separator.2" thousandSeparator="," matInput maxlength="256" [formControl]="fcDescuentoCantidad" autocomplete="off" (keydown)="setDescuentoActive($event, 1)" (keyup)="calcDescuento()">
  <span class="claseSpan" >{{objMoneda?.codigo}}</span>
</td>

<td>
  <input class="table-input text-center precioInput" type="text" matInput maxlength="256" [formControl]="fcDescuentoPorcentaje" autocomplete="off" (keyup)="calcDescuento()" (keydown)="setDescuentoActive($event, 2)">
  <span class="porcentageSpan" >%</span>
</td>

<td >
  <div class="bs-content" style="padding: 0 5px;">
    <div class="bs-row">
      <div class="bs-col" style="padding-left: 2px; padding-right: 2px;" *ngFor="let itemImpuesto of lstInnerImpuestos">
        <mat-checkbox [disabled]="objChkBox.disabled" matTooltip="{{itemImpuesto.impuesto}}: {{itemImpuesto.total | currency}} {{codigoMonedaPartida}}" [(ngModel)]="itemImpuesto['checked']" (ngModelChange)="chkImporteSelected_changeEvent()"></mat-checkbox>
      </div>
    </div>
  </div>
</td>

<td>
  <span class="iconSpan" >$</span>
  <div class="table-input text-right claseInput">{{importe | number:'1.2-2'}}</div>
  <span class="claseSpan" >{{objMoneda?.codigo}}</span>
</td>
