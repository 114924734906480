import {AfterViewInit, Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {OrdenesServiciosService} from "../../../../../services/ordenes-servicios.service";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {FormOptimizer} from "../../../../../libraries/formOptimizer";
import {OrdenesPedidosOrdenesServiciosGerenciamientosFicherosIndexComponent} from "./ficheros/index/index.component";

@Component({
  selector: 'app-gerenciamiento',
  templateUrl: './gerenciamiento.component.html',
  styleUrls: ['./gerenciamiento.component.scss']
})
export class OrdenesPedidosOrdenesServiciosGerenciamientoComponent implements OnInit, AfterViewInit {

  @Output('onClose') onClose : EventEmitter<OrdenesPedidosOrdenesServiciosGerenciamientoComponent> = new EventEmitter<OrdenesPedidosOrdenesServiciosGerenciamientoComponent>();

  public lstGerenciamientosLog : Array<any> = [];

  public objLoader = {
    type      : 'loader',
    visible   : false
  };

  public objChkBox = {
    type      : 'checkbox',
    disabled  : false
  };

  public formComponents: Array<any> = [
    this.objChkBox,
    this.objLoader
  ];

  constructor(
    private objOrdenesServiciosService: OrdenesServiciosService,
    private objDialogRef: MatDialogRef<OrdenesPedidosOrdenesServiciosGerenciamientoComponent>,
    private objMatDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    setTimeout( ()=> this.init(), 200);
  }

  public init() : void {
    this.objOrdenesServiciosService.gerenciamiento(this.data.idOrdenServicio)
      .subscribe( objResponse=> {
        this.lstGerenciamientosLog = objResponse.result?.gerenciamientos;

        FormOptimizer.formEnable(this.formComponents);
      }, error => {
        FormOptimizer.formEnable(this.formComponents);
        console.log(error);
      });
  }

  public chkUnidadesMarcasSelected_changeEvent(event : any ) : void {

  }

  public chkItemUnidadesMarcasSelected_changeEvent() : void {

  }

  public btnOrdenServicioGerenciamiento_clickEvent(itemGerenciamientoLog : any) : void {
    let objDialog = this.objMatDialog.open(OrdenesPedidosOrdenesServiciosGerenciamientosFicherosIndexComponent, {
      width         : '76%',
      maxWidth      : '700px',
      height        : '76%',
      maxHeight     : '700px',
      data          : itemGerenciamientoLog,
      disableClose  : true
    });
  }

  public close(): void {
    this.objDialogRef.close();
    this.onClose.emit(this);
  }

}
