import {AfterViewInit, Component, EventEmitter, Inject, OnInit, Output, ViewChild} from '@angular/core';
import { FormOptimizer } from 'src/app/libraries/formOptimizer';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {AuthService} from "../../../../services/auth.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {UsuariosService} from "../../../../services/usuarios.service";
import {Globals} from "../../../../libraries/globals";
import {AlertComponent} from "../../../../components/alert/alert.component";
import {EmailStatusComponent} from "../../../../components/email-status/email-status.component";
import {Crypto} from "../../../../libraries/crypto";
import {InputAutocompleteComponent} from "../../../../components/input-autocomplete/input-autocomplete.component";

@Component({
  selector: 'app-settings-mail-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class SettingsMailIndexComponent implements OnInit, AfterViewInit {

  @Output('onClose') onClose : EventEmitter<SettingsMailIndexComponent> = new EventEmitter<SettingsMailIndexComponent>();
  @ViewChild('txtCliente')          txtCliente          : InputAutocompleteComponent;
  @ViewChild('txtClienteContacto')  txtClienteContacto  : InputAutocompleteComponent;

  emailPattern: string = "^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$";

  stateOptions: any[];
  value1: string = "off";
  public strGmail : string = '@gmail.com';
  public objUsuario : any;
  public objUsuarioAuth : any;
  public lstCifradosTipos : Array<any> = [];
  public validEmail     : boolean = true;
  public fgUsuarioSettingSendEmail: FormGroup = new FormGroup({
    correo    : new FormControl('', Validators.pattern(this.emailPattern)),
    remitente : new FormControl(''),
    password  : new FormControl(''),
    puerto    : new FormControl(''),
    servidor  : new FormControl(''),
    cifrado   : new FormControl('')
  });

  public visibility : boolean = false;

  public objLoader = {
    type            : 'loader',
    visible         : true
  }

  public btnAceptar = {
    type            : 'button',
    disabled        : false
  }

  public btnCancelar = {
    type            : 'button',
    disabled        : false
  }

  public formComponents : Array<any> = [
    this.objLoader,
    this.btnAceptar,
    this.btnCancelar,
    this.fgUsuarioSettingSendEmail
  ];

  constructor(
    private objDialogRef: MatDialogRef<SettingsMailIndexComponent>,
    private objMatDialog: MatDialog,
    private objAuthService : AuthService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private objUsuariosService : UsuariosService
  ) {
    this.objUsuarioAuth = objAuthService.usuario;
    this.stateOptions = [{label: 'Personalizado', value: 'off'}, {label: 'Gmail', value: 'on'}];
  }

  ngOnInit(): void {
    FormOptimizer.formDisable(this.formComponents);
  }

  ngAfterViewInit() {
    setTimeout(()=> this.init(), 200);
  }

  public init():void {
    this.objUsuariosService.editSettingEmail(this.data.idUsuario)
      .subscribe(objResponse => {
        this.objUsuario = objResponse.result;

        let correo : string = (Globals.exist(this.objUsuario.correo))?this.objUsuario.correo:'';
        let servidor : string = (Globals.exist(this.objUsuario.servidor))?this.objUsuario.servidor:'';

        this.lstCifradosTipos = this.objUsuario.cifradosTipos;
        if(Globals.exist(this.objUsuario.idCifradoTipo)
          && this.objUsuario?.idCifradoTipo == 2
          && correo.includes(this.strGmail)
          && servidor == 'smtp.gmail.com'
        ) {
          this.value1 = 'on';
        } else {
          this.value1 = 'off';
        }
        this.selectButton_clickEvent(this.value1);

        FormOptimizer.formEnable(this.formComponents);
      }, error => {
        FormOptimizer.formEnable(this.formComponents);
      });
  }

  public initGmailConfigurationEmail() : void {
    let correo : string = (Globals.exist(this.objUsuario.correo))?this.objUsuario.correo:'';
    let remitente : string = (Globals.exist(this.objUsuario.correo))?this.objUsuario.remitente:'';
    let servidor : string = (Globals.exist(this.objUsuario.servidor))?this.objUsuario.servidor:'';

    if(correo.includes(this.strGmail) && servidor == 'smtp.gmail.com') {
      let correo : string = this.objUsuario.correo.replace(this.strGmail, '')
      this.fgUsuarioSettingSendEmail.controls['correo'].setValue(correo);
      this.fgUsuarioSettingSendEmail.controls['remitente'].setValue(remitente);
      let password : string = Crypto.descrifrar(this.objUsuario.password);
      this.fgUsuarioSettingSendEmail.controls['password'].setValue(password);
    } else {
      this.fgUsuarioSettingSendEmail.controls['correo'].setValue('');
      this.fgUsuarioSettingSendEmail.controls['password'].setValue('');
    }
    this.fgUsuarioSettingSendEmail.controls[ 'puerto' ].setValue('465');
    this.fgUsuarioSettingSendEmail.controls[ 'puerto' ].disable();
    this.fgUsuarioSettingSendEmail.controls[ 'servidor' ].setValue('smtp.gmail.com');
    this.fgUsuarioSettingSendEmail.controls[ 'servidor' ].disable();
    this.fgUsuarioSettingSendEmail.controls[ 'cifrado' ].setValue(2);
    this.fgUsuarioSettingSendEmail.controls[ 'cifrado' ].disable();
  }

  public initOtrosConfigurationEmail() : void {

    if(Globals.exist(this.objUsuario.correo) && Globals.exist(this.objUsuario.servidor)) {
      this.fgUsuarioSettingSendEmail.controls['correo'].setValue(this.objUsuario.correo);
      this.fgUsuarioSettingSendEmail.controls['remitente'].setValue(this.objUsuario.remitente);
      let password : string = Crypto.descrifrar(this.objUsuario.password);
      this.fgUsuarioSettingSendEmail.controls['password'].setValue(password);
      this.fgUsuarioSettingSendEmail.controls['puerto'].setValue(this.objUsuario.puerto);
      this.fgUsuarioSettingSendEmail.controls['servidor'].setValue(this.objUsuario.servidor);
      if(Globals.exist(this.objUsuario?.idCifradoTipo)) this.fgUsuarioSettingSendEmail.controls['cifrado'].setValue(this.objUsuario.idCifradoTipo);
    } else {
      this.fgUsuarioSettingSendEmail.controls['correo'].setValue('');
      this.fgUsuarioSettingSendEmail.controls['password'].setValue('');
      this.fgUsuarioSettingSendEmail.controls['puerto'].setValue('');
      this.fgUsuarioSettingSendEmail.controls['servidor'].setValue('');
      this.fgUsuarioSettingSendEmail.controls['cifrado'].setValue('');
    }
    this.fgUsuarioSettingSendEmail.controls[ 'puerto' ].enable();
    this.fgUsuarioSettingSendEmail.controls[ 'servidor' ].enable();
    this.fgUsuarioSettingSendEmail.controls[ 'cifrado' ].enable();
  }

  public formUsuarioConfiguracionCorreoEnvio_submitEvent() : void {
    if( this.fgUsuarioSettingSendEmail.valid) {
      FormOptimizer.formDisable(this.formComponents);

      let correo : string = (this.value1=='on')?FormOptimizer.formDataString( this.fgUsuarioSettingSendEmail.controls[ 'correo' ]).replace(this.strGmail, '') + this.strGmail:FormOptimizer.formDataString( this.fgUsuarioSettingSendEmail.controls[ 'correo' ]);

      let password : string = FormOptimizer.formDataString( this.fgUsuarioSettingSendEmail.controls[ 'password' ]);

      let objSettingSendEmail:any = {
        idUsuario       : this.objUsuario.idUsuario,
        correo          : correo,
        password        : Crypto.crifrar(password),
        remitente       : FormOptimizer.formDataString( this.fgUsuarioSettingSendEmail.controls[ 'remitente' ]),
        puerto          : FormOptimizer.formDataString( this.fgUsuarioSettingSendEmail.controls[ 'puerto' ]),
        servidor        : FormOptimizer.formDataString( this.fgUsuarioSettingSendEmail.controls[ 'servidor' ]),
        idCifradoTipo   : FormOptimizer.formDataString( this.fgUsuarioSettingSendEmail.controls[ 'cifrado' ]),
        validEmail      : this.validEmail
      }

      let objDialog = this.objMatDialog.open(EmailStatusComponent, {
        width         : '96%',
        maxWidth      : '600px',
        height        : '96%',
        maxHeight     : '300px',
        data          : objSettingSendEmail,
        disableClose  : true
      });

      objDialog.componentInstance.onChange.subscribe( ( itemSettingsMailIndexComponent : SettingsMailIndexComponent) => {
        this.objLoader.visible = false;
      });

      objDialog.componentInstance.onClose.subscribe( ( itemSettingsMailIndexComponent : SettingsMailIndexComponent) => {
        FormOptimizer.formEnable(this.formComponents);
      });

      objDialog.componentInstance.onCloseAll.subscribe( ( itemSettingsMailIndexComponent : SettingsMailIndexComponent) => {
        this.close();
      });

    } else {
      this.objMatDialog.open(AlertComponent, Globals.confirmConfig({titulo : "Error de formulario", mensaje : "Formulario incompleto.", autoCloseDelay : 3000}));
    }
  }

  public close() : void {
    this.objDialogRef.close();
    this.onClose.emit(this);
  }

  public selectButton_clickEvent(event : string) : void {
    if(event == 'on') {
      let emailPattern = "";
      this.fgUsuarioSettingSendEmail.controls[ 'correo' ].setValidators(Validators.pattern(emailPattern));
      this.initGmailConfigurationEmail();
    } else {
      this.fgUsuarioSettingSendEmail.controls[ 'correo' ].setValidators(Validators.pattern(this.emailPattern));
      this.initOtrosConfigurationEmail();
    }
  }
}
