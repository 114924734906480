import {AfterViewInit, Component, ElementRef, EventEmitter, Inject, OnInit, Output, ViewChild} from '@angular/core';
import { saveAs }                                   from "file-saver";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";

import { ApplicationConfig }                        from "../../../../../../../libraries/application-config";
import { Globals }                                  from "../../../../../../../libraries/globals";
import { Utils }                                    from "../../../../../../../libraries/utils";
import { GerenciamientosFicherosService }           from "../../../../../../../services/gerenciamientos/gerenciamientos-ficheros.service";
import { AlertComponent }                           from "../../../../../../../components/alert/alert.component";
import { FileViewerComponent }                      from "../../../../../../../components/file-viewer/file-viewer.component";

@Component({
  selector: 'app-ordenes-pedidos-ordenes-servicios-gerenciamientos-ficheros-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})

export class OrdenesPedidosOrdenesServiciosGerenciamientosFicherosIndexComponent implements OnInit, AfterViewInit {

  @ViewChild('dataTable') dataTable : ElementRef<HTMLTableElement>;
  @Output('onClose')      onClose   : EventEmitter<OrdenesPedidosOrdenesServiciosGerenciamientosFicherosIndexComponent> = new EventEmitter<OrdenesPedidosOrdenesServiciosGerenciamientosFicherosIndexComponent>();

  public ApplicationConfig            = ApplicationConfig;
  public Globals                      = Globals;
  public lstGerenciamientosFicheros   : Array<any> = [];
  public numberItemsVigentes          : number = 0;
  public fileSizeVigentes             : number = 0;
  public pdfValidate                  : Array<String> = ['gif','webp','jpeg','jpg','png','pdf'];
  public maxWidth                     : number = 0;

  public boolChkVigentes     : boolean = false;
  public boolChkSomeVigentes : boolean = false;

  public objLoader = {
    type            : 'loader',
    visible         : true
  }

  constructor(
    private objGerenciamientosFicherosService : GerenciamientosFicherosService,
    private objDialogRef: MatDialogRef<OrdenesPedidosOrdenesServiciosGerenciamientosFicherosIndexComponent>,
    private objMatDialog : MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    setTimeout( () => this.init(), 200);
  }

  public init() : void {
    this.objLoader.visible = true;
    this.objGerenciamientosFicherosService.index(this.data.idGerenciamientoLog).subscribe( objResponse => {

      this.lstGerenciamientosFicheros = objResponse.result.gerenciamientosFicheros;
      this.initSeleccion();
      this.objLoader.visible = false;
    }, ()=> {
      this.objLoader.visible = false;
    });
  }

  public initSeleccion() : void {
    this.chkClientesFicherosVigentes_changeEvent(false);
  }

  public chkClientesFicherosVigentes_changeEvent(event : any ) : void {
    this.boolChkVigentes = event;
    this.fileSizeVigentes = 0;
    this.numberItemsVigentes = 0;
    this.lstGerenciamientosFicheros.forEach(itemGerenciamientoLogFichero => {
      itemGerenciamientoLogFichero.checked = this.boolChkVigentes;
      if (this.boolChkVigentes) {
        this.fileSizeVigentes += itemGerenciamientoLogFichero.tamano;
        this.numberItemsVigentes ++;
      }
    });
    this.boolChkSomeVigentes = this.lstGerenciamientosFicheros.some(itemGerenciamientoLogFichero => itemGerenciamientoLogFichero.checked);
  }

  public chkItemClienteFicheroVigente_changeEvent( index : number ) : void {
    this.boolChkVigentes = this.lstGerenciamientosFicheros.every(itemGerenciamientoLogFichero => itemGerenciamientoLogFichero.checked);
    this.boolChkSomeVigentes = this.lstGerenciamientosFicheros.some(itemGerenciamientoLogFichero => itemGerenciamientoLogFichero.checked);
    if (this.lstGerenciamientosFicheros[index].checked) {
      this.fileSizeVigentes += this.lstGerenciamientosFicheros[index].tamano;
      this.numberItemsVigentes ++;
    } else {
      this.fileSizeVigentes -= this.lstGerenciamientosFicheros[index].tamano;
      this.numberItemsVigentes --;
    }
  }

  public viewFile_clickEvente(itemGerenciamientoLogFichero : any) : void {
    if(this.pdfValidate.includes(itemGerenciamientoLogFichero.extension)) {
      let objDialog = this.objMatDialog.open(FileViewerComponent, {
        width     : '100%',
        maxWidth  : '100%',
        height    : '100%',
        maxHeight : '100%',
        data: {
          extension : 'pdf',
          fileName  : itemGerenciamientoLogFichero.nombre + '.pdf',
          url       : 'api/gerenciamientos/ficheros/' + itemGerenciamientoLogFichero.idGerenciamientoLogFichero + '/download'
        },
        backdropClass : 'backdropBackground',
        panelClass    : 'visualizarPanelClass',
        disableClose  : true
      });
    } else {
      this.btnClienteFicheroDownload_clickEvent(itemGerenciamientoLogFichero);
    }
  }

  public btnClienteFicheroDownload_clickEvent( itemGerenciamientoLogFichero : any) : void {
    this.objLoader.visible = true;
    this.objGerenciamientosFicherosService.download(itemGerenciamientoLogFichero.idGerenciamientoLogFichero)
      .subscribe( objResponse => {
        this.objLoader.visible = false;
        saveAs(objResponse, itemGerenciamientoLogFichero.nombre + "." + itemGerenciamientoLogFichero.extension);
        // Wait.hide(this.mainContainer);

      }, () => {
        this.objLoader.visible = false;
        this.objMatDialog.open(AlertComponent, Globals.alertConfig({titulo : "Error de comunicación", mensaje : "Ocurrió un error de comunicación con el servidor.", autoCloseDelay : 3000}));
      });
  }

  public btnVigentesDescargarSeleccionados_clickEvent() : void {
    this.objLoader.visible = true;
    let lstGerenciamientosLogFicherosSeleccionados = this.lstGerenciamientosFicheros.filter(itemGerenciamientoLogFichero => itemGerenciamientoLogFichero.checked).map(itemGerenciamientoLogFichero => {return { idGerenciamientoLogFichero : itemGerenciamientoLogFichero.idGerenciamientoLogFichero}});
    let objDescargarZip = {
      ficheros  : lstGerenciamientosLogFicherosSeleccionados
    };

    const numeroEconomico = this.data.numeroEconomico.toLowerCase().replaceAll(' ', '_');
    const nombreZip = `${ numeroEconomico }_${ Utils.formatDateTime() }.zip`;

    this.objGerenciamientosFicherosService.zip(objDescargarZip)
      .subscribe( objResponse => {
        saveAs(objResponse, nombreZip);
        this.initSeleccion();
        this.objLoader.visible = false;
      }, () => {
        this.objMatDialog.open(AlertComponent, Globals.alertConfig({titulo : "Error de comunicación", mensaje : "Ocurrió un error de comunicación con el servidor.", autoCloseDelay : 3000}));
        this.objLoader.visible = false;
        this.init();
      });
  }

  public close() : void {
    this.objDialogRef.close();
    this.onClose.emit(this);
  }
}
