<mat-toolbar color="primary" class="topbar telative">
  <!-- ============================================================== -->
  <!-- Logo - style you can find in header.scss -->
  <!-- ============================================================== -->
  <div class="navbar-header">
    <a class="navbar-brand" [routerLink]="['/cp/inicio']">
      <!-- Logo icon -->
      <b>
        <!--You can put here icon as well // <i class="wi wi-sunset"></i> //-->
        <!-- Dark Logo icon -->
        <img src="assets/images/logo-icon.png" alt="homepage" class="dark-logo">
        <!-- Light Logo icon -->
        <img src="assets/images/logo-light-icon.png" alt="homepage" class="light-logo">
      </b>
      <!--End Logo icon -->
      <!-- Logo text -->
      <span fxShow="false" fxShow.gt-xs>
        <!-- dark Logo text -->
        <img src="assets/images/logo-text.png" alt="homepage" class="dark-logo">
        <!-- Light Logo text -->
        <img src="assets/images/logo-light-text.png" class="light-logo" alt="homepage">
      </span>
    </a>
  </div>
  <span fxFlex></span>
<!--  <app-header></app-header>-->
</mat-toolbar>


<div class="delta-side-container">
  <div class="delta-sidenav" [style.width.px]="objCookiesService.getBoolean('mainMenuToogle') ? 260 : 50">
    <div class="sidenav-content-area">
      <app-sidebar class="app-sidebar" [toogle]="objCookiesService.getBoolean('mainMenuToogle')"></app-sidebar>
    </div>
    <div class="resize-area" (click)="btnResizeMenu_clickEvent()">
      <div class="resize-area-border"></div>
      <div class="resize-area-button">
        <span class="material-icons" *ngIf="objCookiesService.getBoolean('mainMenuToogle')">navigate_before</span>
        <span class="material-icons" [style.marginLeft.px]="2" *ngIf="!objCookiesService.getBoolean('mainMenuToogle')">navigate_next</span>
      </div>
    </div>
  </div>
  <div class="delta-main-content page-wrapper">
      <app-breadcrumb></app-breadcrumb>
      <div class="page-content">
          <app-spinner [width]="'calc(100% - 260px)'" [height]="'calc(100% - 70px)'"></app-spinner>
        <router-outlet>
        </router-outlet>
      </div>
  </div>
</div>
