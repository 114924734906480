<div class="mat-dialog-title">
  <div class="title">Orden Pedido{{folio}}</div>
  <div class="spacer"></div>
  <div class="buttons">
    <button type="button" class="title-button" (click)="close()">
      <mat-icon class="title-button-icon">clear</mat-icon>
    </button>
  </div>
</div>

<div class="mat-dialog-body mat-dialog-no-actions">

  <div class="bs-content-full-area" [style.height.%]="100">
    <div class="bs-sidebar-left" [style.width.px]="250">
      <div class="bs-content">
        <div class="row">
          <div class="col text-center">
            <span class="material-icons" [style.fontSize.px]="80">person</span>
          </div>
        </div>

        <div class="bs-row">
          <div class="bs-col">
            <mat-selection-list [(ngModel)]="objScene" [multiple]="false">
              <mat-list-option  [value]="'CLIENTE'">Información general</mat-list-option>
              <mat-list-option  [value]="'REQUERIMIENTO'">Requerimientos</mat-list-option>
              <mat-list-option  [value]="'SERVICIOS'">Ordenes de servicios</mat-list-option>
<!--              <mat-list-option  [value]="'CONTRATOS'">Contratos</mat-list-option>-->
            </mat-selection-list>
          </div>
        </div>
      </div>
    </div>

    <div class="bs-viewport">

      <div class="datatable-responsive layout" [hidden]="objScene[0] != 'CLIENTE'">
        <div class="bs-content">
          <div fxLayout="row wrap">
            <!-- Card column -->
            <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
              <mat-card class="panel-content-card">
                <div class="content-card" style="padding: 20px; ">
                  <div class="card-row">
                    <div class="card-col">
                      <div class="img-foto" [style.background]="'url(' + objApplicationConfig.ENDPOINT + 'api/empresas/logotipo/' + objOrdenPedido.idEmpresa + '/512)'"></div>
                    </div>
                    <div class="card-col" [style.paddingLeft.px]="30">
                      <div class="card-row">
                        <div class="card-col-fiel card-fiel" style="font-size: 18px;font-weight: 500;" >{{objOrdenPedido.empresaNombreComercial}}</div>
                      </div>
                      <div class="card-row">
                        <div class="card-col-fiel card-fiel">{{objOrdenPedido.empresaRazonSocial}}</div>
                      </div>
                      <div class="card-row" [style.paddingTop.px]="10">
                        <div class="card-col-fiel card-fiel">{{objOrdenPedido.empresaEmail}}</div>
                      </div>
                      <div class="card-row">
                        <div class="card-col-fiel card-fiel">{{objOrdenPedido.empresaTelefono}}</div>
                      </div>
                      <div class="card-row">
                        <div class="card-col-fiel card-fiel">{{objOrdenPedido.empresaDireccionFiscal}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-card>
            </div>
          </div>

          <div fxLayout="row wrap">
            <!-- Card column -->
            <div fxFlex.gt-lg="100" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
              <mat-card class="panel-content-card">
                <div class="content-card">
                  <div class="card-row br_btm">
                    <div class="card-col-title">Cliente</div>
                  </div>
                  <div class="card-row">
                    <div class="card-col">
                      <div class="card-row" style="margin: 5px 20px 0 20px">
                        <div class="card-col-label card-label">Nombre comercial</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px 0 20px">
                        <div class="card-col-label card-label">Razón social</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px 0 20px">
                        <div class="card-col-label card-label">R.F.F</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px 0 20px">
                        <div class="card-col-label card-label">Dirección fiscal</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px; padding-bottom: 15px">
                        <div class="card-col-label card-label">Dirección social</div>
                      </div>
                    </div>
                    <div class="card-col">
                      <div class="card-row" style="margin: 5px 20px 0 20px">
                        <div class="card-col-fiel card-fiel">{{objOrdenPedido.clienteNombreComercial}}</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px 0 20px">
                        <div class="card-col-fiel card-fiel">{{objOrdenPedido.clienteRazonSocial}}</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px 0 20px">
                        <div class="card-col-fiel card-fiel">{{objOrdenPedido.clienteRfc}}</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px 0 20px">
                        <div class="card-col-fiel card-fiel">{{objOrdenPedido.clienteDireccionFiscal}}</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px; padding-bottom: 15px">
                        <div class="card-col-fiel card-fiel">{{objOrdenPedido.clienteDireccionSocial}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-card>
            </div>

            <div fxFlex.gt-lg="100" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
              <mat-card class="panel-content-card">
                <div class="content-card">
                  <div class="card-row br_btm">
                    <div class="card-col-title">Contacto</div>
                  </div>
                  <div class="card-row">
                    <div class="card-col">
                      <div class="card-row" style="margin: 5px 20px 0 20px">
                        <div class="card-col-label card-label">Nombre</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px 0 20px">
                        <div class="card-col-label card-label">Puesto</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px 0 20px">
                        <div class="card-col-label card-label">Teléfono</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px; padding-bottom: 15px">
                        <div class="card-col-label card-label">Correo</div>
                      </div>
                    </div>
                    <div class="card-col">
                      <div class="card-row" style="margin: 5px 20px 0 20px">
                        <div class="card-col-fiel card-fiel">{{objOrdenPedido.contactoNombreCompleto}}</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px 0 20px">
                        <div class="card-col-fiel card-fiel">{{objOrdenPedido.contactoPuesto}}</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px 0 20px">
                        <div class="card-col-fiel card-fiel">{{objOrdenPedido.contactoTelefono}}</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px; padding-bottom: 15px">
                        <div class="card-col-fiel card-fiel">{{objOrdenPedido.contactoEmail}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-card>
            </div>
          </div>

        </div>
      </div>

      <div class="datatable-responsive layout" [hidden]="objScene[0] != 'REQUERIMIENTO'">
        <div class="bs-content">
          <div fxLayout="row wrap">
            <!-- Card column -->
            <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
              <mat-card class="panel-content-card">
                <div class="content-card">
                  <div class="card-row br_btm">
                    <div class="card-col-title">Alcance</div>
                  </div>
                  <div class="card-row" style="margin: 5px 20px 0 20px">
                    <div class="card-col-label card-fiel">{{objCotizacion?.alcance}}</div>
                  </div>
                </div>
              </mat-card>
            </div>
          </div>

          <div fxLayout="row wrap">
            <!-- Card column -->
            <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
              <mat-card class="panel-content-card">
                <div class="content-card">
                  <div class="card-row br_btm">
                    <div class="card-col-title">Descripción del servicio</div>
                  </div>
                  <div class="card-row" style="margin: 5px 20px 0 20px">
                    <div class="card-col-label card-fiel">{{objCotizacion?.descripcion}}</div>
                  </div>
                </div>
              </mat-card>
            </div>
          </div>
        </div>
        <div class="card-table-content">
          <div class="title-card-bar">
            <span class="title">Partidas aprobadas</span>
          </div>
          <table class="datatable">
            <thead>
            <tr>
              <th [style.width.px]="40" class="text-center">No.</th>
              <th [style.width.px]="100">Servicio</th>
              <th>Descripción</th>
              <th [style.width.px]="100">Cantidad</th>
              <th [style.width.px]="100">Unidad</th>
            </tr>
            </thead>
            <tbody>

            <tr *ngFor="let itemCotizacionPartidas of lstCotizacionPartidas; let index = index;">
              <td class="text-center">{{index + 1}}</td>
              <td>{{ itemCotizacionPartidas?.servicio?.servicio }}</td>
              <td>{{ itemCotizacionPartidas?.concepto }}</td>
              <td class="text-center">{{ itemCotizacionPartidas?.cantidad }}</td>
              <td>{{ itemCotizacionPartidas?.unidadMedida?.unidadMedida }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="datatable-responsive layout" [hidden]="objScene[0] != 'SERVICIOS'" [style.height.%]="100">
          <div class="options-bar" style="width: 100%">
            <div class="option">
              <button type="button" matTooltip="Nuevo" [disabled]="objOrdenPedido?.idOrdenPedidoEstatus == 3 || !objPrivilegios.ORDENES_SERVICIOS_CREATE" (click)="ordenServicioNuevo_clickEvent()">
                <span class="material-icons">add</span>
              </button>
            </div>

            <div class="option" [hidden]="objOrdenPedido?.idOrdenPedidoEstatus != 2 || !isFinalize">
              <button type="button" [disabled]="!objPrivilegios.ORDENES_PEDIDOS_FINALIZE" matTooltip="Finalizar orden de pedido" (click)="ordenServicioFinalizar_clickEvent()">
                <span class="material-icons">task_alt</span>
              </button>
            </div>

<!--            <div class="option">-->
<!--              <button type="button" matTooltip="Generar contrato" (click)="generarContrato_clickEvent()">-->
<!--                <div class="icon-svg icon-agreement"></div>-->
<!--              </button>-->
<!--            </div>-->
          </div>

        <div class="datatable-responsive" [style.height]="'calc(100% - 46px)'">
          <table class="datatable" >
            <thead>
            <tr>
              <th [style.width.px]="40" class="text-center">
                <mat-checkbox [checked]="boolChkOrdenServicio" (change)="chkOrdenServicioSelected_changeEvent($event.checked)"></mat-checkbox>
              </th>
              <th [style.minWidth.px]="35">No.</th>
              <th [style.minWidth.px]="200">Folio</th>
              <th [style.minWidth.px]="90">Estatus</th>
              <th [style.minWidth.px]="80">Fecha</th>
              <th [style.minWidth.px]="110">Monto del servicio</th>
              <th [style.minWidth.px]="60">Contrato</th>
              <th [style.minWidth.px]="100">Unidades</th>
              <th [style.minWidth.px]="300">Operadores</th>
              <th [style.minWidth.px]="300">Objetivo contrato</th>
              <th [style.minWidth.px]="110">Fecha programada</th>
              <th [style.minWidth.px]="120">Salida de origen</th>
              <th [style.minWidth.px]="120">Llegada de destino</th>
              <th [style.minWidth.px]="120">Salida de destino</th>
              <th [style.minWidth.px]="120">Llegada al origen</th>
              <th [style.minWidth.px]="180" colspan="2">Fecha duración Desde/Hasta</th>
            </tr>
            </thead>
            <tbody>

            <tr *ngFor="let itemOrdenServicio of lstOdernesServicios; let index = index;">
              <td class="text-center">
                <mat-checkbox [(ngModel)]="itemOrdenServicio.checked" (ngModelChange)="chkItemOrdenServicioSelected_changeEvent()"></mat-checkbox>
              </td>
              <td class="text-center">{{(index + 1)}}</td>
              <td class="text-center" style="padding:0 5px 0 10px">
                <div class="item-menu-context">
                  <div class="text-column text-ellipsis badge button-badge" [style.width.px]="145" (click)="verPdf_clickEvent(itemOrdenServicio)">{{itemOrdenServicio.folio}}</div>

                  <div class="spacer"></div>

                  <button class="datatable-button menu-contextual" [matMenuTriggerFor]="menuContextual">
                    <mat-icon class="datatable-button-icon">more_vert</mat-icon>
                  </button>
                  <mat-menu #menuContextual="matMenu" class="itemMenu">
                    <button mat-menu-item [disabled]="!objPrivilegios.ORDENES_SERVICIOS_VIEW_PDF" (click)="verPdf_clickEvent(itemOrdenServicio)">
                      <i class="far fa-file-pdf"></i>
                      <span>ver PDF Orden de orden de servicio</span>
                    </button>

                    <button mat-menu-item [disabled]="!objPrivilegios.ORDENES_SERVICIOS_VIEW_PDF" *ngIf="itemOrdenServicio.hasContrato" (click)="verContrato_clickEvent(itemOrdenServicio)">
                      <i class="far fa-file-pdf"></i>
                      <span>Ver PDF del contrato</span>
                    </button>

                    <button mat-menu-item [disabled]="!objPrivilegios.ORDENES_SERVICIOS_FINALIZE" *ngIf="itemOrdenServicio.idOrdenServicioEstatus == 2" (click)="btnFinalizarOrdenServicio_clickEvent(itemOrdenServicio)">
                      <mat-icon class="material-icons-outlined">check_circle</mat-icon>
                      <span>Finalizar orden de servicio</span>
                    </button>

                    <button mat-menu-item (click)="btnOrdenServicioGerenciamiento_clickEvent(itemOrdenServicio)">
                      <mat-icon class="material-icons-outlined">work_history</mat-icon>
                      <span>Gerenciamiento</span>
                    </button>

                    <button mat-menu-item [disabled]="!objPrivilegios.ORDENES_SERVICIOS_LIST" (click)="btnPagoOrdenServicio_clickEvent(itemOrdenServicio)">
                      <mat-icon class="material-icons-outlined">paid</mat-icon>
                      <span>Monto del servicio</span>
                    </button>
                  </mat-menu>
                </div>
              </td>
              <td class="text-center"><span class="badge" [class]="['estatus-' + itemOrdenServicio.idOrdenServicioEstatus]">{{itemOrdenServicio.ordenServicioEstatus}}</span></td>
              <td class="text-center">{{itemOrdenServicio.fechaNatural}}</td>
              <td class="text-right">{{itemOrdenServicio.monto | currency}}</td>
              <td class="hasContrato">
                <span *ngIf="itemOrdenServicio.hasContrato" class="material-icons view-contrato" (click)="verContrato_clickEvent(itemOrdenServicio)">check_circle</span>
                <span *ngIf="!itemOrdenServicio.hasContrato" class="material-icons">cancel</span>
              </td>
              <td>
                <table class="datatable table-miembro">
                  <tbody>
                  <tr *ngFor="let itemMiembro of itemOrdenServicio?.unidades">
                    <td>{{itemMiembro?.numeroEconomico}}</td>
                  </tr>
                  </tbody>
                </table>
              </td>
              <td>
                <table class="datatable table-miembro">
                  <tbody>
                  <tr *ngFor="let itemMiembro of itemOrdenServicio?.operadores">
                    <td>{{itemMiembro?.nombreCompleto}}</td>
                    <td [style.width.px]="95">{{itemMiembro?.puesto}}</td>
                  </tr>
                  </tbody>
                </table>
              </td>
              <td>{{itemOrdenServicio.objetivoContrato}}</td>
              <td class="text-center">{{itemOrdenServicio.programadaFechaNatural}}</td>
              <td class="text-center">{{itemOrdenServicio.salidaOrigenFechaHoraNatural}}</td>
              <td class="text-center">{{itemOrdenServicio.llegadaDestinoFechaHoraNatural}}</td>
              <td class="text-center">{{itemOrdenServicio.salidaDestinoFechaHoraNatural}}</td>
              <td class="text-center">{{itemOrdenServicio.llegadaOrigenFechaHoraNatural}}</td>
              <td class="text-center">{{itemOrdenServicio.duracionDesdeFechaNatural}}</td>
              <td class="text-center">{{itemOrdenServicio.duracionHastaFechaNatural}}</td>


            </tr>
            </tbody>
          </table>
        </div>
        <p-contextMenu class="p-contextmenu-popup" #contextMenu [model]="items"></p-contextMenu>
      </div>

      <div class="datatable-responsive layout" [hidden]="objScene[0] != 'CONTRATOS'">
      </div>

    </div>
  </div>

</div>

<div class="mat-dialog-loader mat-dialog-no-actions">
  <mat-progress-bar class="loader" *ngIf="objLoader.visible" color="accent" mode="indeterminate"></mat-progress-bar>
</div>


